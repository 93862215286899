import { RESET_STATE, SET_CLAIMS_LIST, SET_IS_CLAIMS_LIST_LOADING, SET_PAGINATION, SET_TABLE_SEARCH } from './actions';

export const initialState = {
	claimsList: [],
	isClaimsListLoading: false,
	pagination: null,
	tableSearch: '',
};

const claimsContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RESET_STATE:
			return initialState;
		case SET_CLAIMS_LIST:
			return { ...state, claimsList: payload };
		case SET_IS_CLAIMS_LIST_LOADING:
			return { ...state, isClaimsListLoading: payload };
		case SET_PAGINATION:
			return { ...state, pagination: payload };
		case SET_TABLE_SEARCH:
			return { ...state, tableSearch: payload };
		default:
			return state;
	}
};

export default claimsContainerReducer;
