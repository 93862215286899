export const GET_CLAIM_DETAILS = 'app/ClaimsDetailsModal/GET_CLAIM_DETAILS';

export const getClaimDetailsAction = payload => ({
	type: GET_CLAIM_DETAILS,
	payload,
});

export const SET_CLAIM_DETAILS = 'app/ClaimsDetailsModal/SET_CLAIM_DETAILS';

export const setClaimDetailsAction = payload => ({
	type: SET_CLAIM_DETAILS,
	payload,
});

export const GET_CLAIM_DOCS = 'app/ClaimsDetailsModal/GET_CLAIM_DOCS';
export const SET_CLAIM_DOCS = 'app/ClaimsDetailsModal/SET_CLAIM_DOCS';
export const POST_CLAIM_DOCS = 'app/ClaimsDetailsModal/POST_CLAIM_DOCS';

export const postClaimDocsAction = payload => ({
	type: POST_CLAIM_DOCS,
	payload,
});

export const setClaimDocsAction = payload => ({
	type: SET_CLAIM_DOCS,
	payload,
});

export const getClaimDocsAction = payload => ({
	type: GET_CLAIM_DOCS,
	payload,
});

export const SET_CLAIM_DOCS_ERRORS = 'app/ClaimsDetailsModal/SET_CLAIM_DOCS_ERRORS';

export const setClaimDocsErrorsAction = payload => ({
	type: SET_CLAIM_DOCS_ERRORS,
	payload,
});

export const SET_DOCS_FILES = 'app/ClaimsDetailsModal/SET_DOCS_FILES';

export const setDocsFilesAction = payload => ({
	type: SET_DOCS_FILES,
	payload,
});

export const GET_CLAIMS_FORM = 'app/ClaimsDetailsModal/GET_CLAIMS_FORM';

export const getClaimsFormAction = payload => ({
	type: GET_CLAIMS_FORM,
	payload,
});

export const SET_CLAIMS_FORM = 'app/ClaimsDetailsModal/SET_CLAIMS_FORM';

export const setClaimsFormAction = payload => ({
	type: SET_CLAIMS_FORM,
	payload,
});

export const GET_CLAIM_EVENTS = 'app/ClaimsDetailsModal/GET_CLAIM_EVENTS';

export const getClaimEventsAction = payload => ({
	type: GET_CLAIM_EVENTS,
	payload,
});

export const SET_CLAIM_EVENTS = 'app/ClaimsDetailsModal/SET_CLAIM_EVENTS';

export const setClaimEventsAction = payload => ({
	type: SET_CLAIM_EVENTS,
	payload,
});

export const SOLVE_CLAIM = 'app/ClaimsDetailsModal/SOLVE_CLAIM';

export const solveClaimAction = payload => ({
	type: SOLVE_CLAIM,
	payload,
});

export const AGREE_CLAIM = 'app/ClaimsDetailsModal/AGREE_CLAIM';

export const agreeClaimAction = payload => ({
	type: AGREE_CLAIM,
	payload,
});

export const REJECT_CLAIM = 'app/ClaimsDetailsModal/REJECT_CLAIM';

export const rejectClaimAction = payload => ({
	type: REJECT_CLAIM,
	payload,
});

export const RESET_STATE = 'app/ClaimsDetailsModal/RESET_STATE';

export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_SELECT_SOLVE_CLAIM = 'app/ClaimsDetailsModal/GET_SELECT_SOLVE_CLAIM';

export const getSelectSolveClaimAction = payload => ({
	type: GET_SELECT_SOLVE_CLAIM,
	payload,
});

export const GET_SELECT_REJECT_CLAIM = 'app/ClaimsDetailsModal/GET_SELECT_REJECT_CLAIM';

export const getSelectRejectClaimAction = payload => ({
	type: GET_SELECT_REJECT_CLAIM,
	payload,
});
