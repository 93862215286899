import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import './styles.less';
import { DOCUMENTS_TAB_ICONS } from '../../../reqs-details-modal/constants';

export const ClaimsDocumentsTab = ({ documentsData, isDocumentsLoading }) => {
	return (
		<div className="claims-documents-tab">
			{isDocumentsLoading ? (
				<Loader active size="big" />
			) : (
				<>
					{documentsData?.length > 0 ? (
						<div className="claims-documents-tab__section">
							<div className="claims-documents-tab__section-docs">
								{documentsData.map(({ type, document_name, link, user, created_at }) => (
									<div
										className={`claims-documents-tab__section-docs-item${
											!link ? ' claims-documents-tab__section-docs-item_disabled' : ''
										}`}
										key={document_name}
									>
										<div className="claims-documents-tab__section-docs-item-info">
											<div className="claims-documents-tab__doc-col-wrapper">
												<div className="claims-documents-tab__doc-col_first">
													<img
														src={
															DOCUMENTS_TAB_ICONS[
																type.icon === 'image' ? 'image' : 'document'
															]
														}
														alt={type.icon}
													/>
													<div className="claims-documents-tab__doc-name">
														{document_name}
													</div>
												</div>
											</div>
											<div className="claims-documents-tab__doc-col_second">
												{user && created_at && (
													<div className="claims-documents-tab__doc-col-item">
														<span className="claims-documents-tab__doc-col-item_bold">
															Прикреплено:{' '}
														</span>
														{`${user.name} ${new Date(created_at).toLocaleDateString(
															'ru-RU',
														)} в ${new Date(created_at).toLocaleTimeString('ru-RU')}`}
													</div>
												)}
											</div>
										</div>
										<div className="claims-documents-tab__doc-col_third">
											<a
												href={link}
												className="claims-documents-tab__doc-col-link"
												target="_blank"
												rel="noopener noreferrer"
											>
												<img src={DOCUMENTS_TAB_ICONS.download} alt="download" />
											</a>
										</div>
									</div>
								))}
							</div>
						</div>
					) : (
						<div className="claims-documents-tab__no-data">Документы не найдены</div>
					)}
				</>
			)}
		</div>
	);
};

ClaimsDocumentsTab.propTypes = {
	documentsData: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			req_id: PropTypes.number.isRequired,
			document_name: PropTypes.string.isRequired,
			filename: PropTypes.string.isRequired,
			link: PropTypes.string,
			type: PropTypes.shape({
				id: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
				icon: PropTypes.string.isRequired,
				group: PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired,
				}).isRequired,
			}).isRequired,
			user: PropTypes.shape({
				id: PropTypes.number.isRequired,
				last_name: PropTypes.string.isRequired,
				first_name: PropTypes.string.isRequired,
				middle_name: PropTypes.string,
				name: PropTypes.string.isRequired,
				email: PropTypes.string.isRequired,
				phone: PropTypes.string.isRequired,
				extension: PropTypes.string.isRequired,
			}),
			created_at: PropTypes.string,
		}),
	).isRequired,
	isDocumentsLoading: PropTypes.bool.isRequired,
};
