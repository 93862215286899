import {
	GET_CLAIM_DETAILS,
	GET_CLAIM_DOCS,
	SET_CLAIM_DOCS_ERRORS,
	GET_CLAIM_EVENTS,
	SOLVE_CLAIM,
	AGREE_CLAIM,
	REJECT_CLAIM,
	RESET_STATE,
	SET_DOCS_FILES,
	SET_CLAIMS_FORM,
	GET_CLAIMS_FORM,
	SET_CLAIM_DETAILS,
	SET_CLAIM_DOCS,
	POST_CLAIM_DOCS,
	SET_CLAIM_EVENTS,
} from './actions';

export const initialState = {
	data: null,
	isClaimDetailsLoading: false,
	claimDocs: [],
	isClaimDocsLoading: false,
	claimsForm: [],
	isClaimsFormLoading: false,
	claimDocsFormErrors: {},
	claimEvents: '',
	isClaimEventsLoading: false,
	isSolving: false,
	solveError: null,
	isAgreeing: false,
	agreeError: null,
	isRejecting: false,
	rejectError: null,
	files: [],
};

const claimsDetailsModalReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_CLAIM_DETAILS:
			return {
				...state,
				isClaimDetailsLoading: true,
				activeClaimId: payload.id,
			};
		case SET_CLAIM_DETAILS:
			return {
				...state,
				isClaimDetailsLoading: false,
				data: payload,
			};
		case GET_CLAIM_DOCS:
			return {
				...state,
				isClaimDocsLoading: true,
			};
		case SET_CLAIM_DOCS:
			return {
				...state,
				isClaimDocsLoading: false,
				claimDocs: payload,
			};
		case POST_CLAIM_DOCS:
			return {
				...state,
				isClaimsFormLoading: true,
			};
		case SET_CLAIM_DOCS_ERRORS:
			return {
				...state,
				claimDocsFormErrors: payload,
			};
		case SET_DOCS_FILES:
			return {
				...state,
				files: payload,
			};
		case GET_CLAIMS_FORM:
			return {
				...state,
				isClaimsFormLoading: true,
			};
		case SET_CLAIMS_FORM:
			return {
				...state,
				isClaimsFormLoading: false,
				claimsForm: payload,
			};
		case GET_CLAIM_EVENTS:
			return {
				...state,
				isClaimEventsLoading: true,
				claimEventsError: null,
			};
		case SET_CLAIM_EVENTS:
			return {
				...state,
				isClaimEventsLoading: false,
				claimEvents: payload,
			};
		case SOLVE_CLAIM:
			return {
				...state,
				isSolving: true,
				solveError: null,
			};

		case AGREE_CLAIM:
			return {
				...state,
				isAgreeing: true,
				agreeError: null,
			};
		case REJECT_CLAIM:
			return {
				...state,
				isRejecting: true,
				rejectError: null,
			};
		case RESET_STATE:
			return {
				...initialState,
			};

		default:
			return state;
	}
};

export default claimsDetailsModalReducer;
