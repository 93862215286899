/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import { sendReasonAction } from './actions';
import { getIsModalShownSelector, getIsReasonSendingSelector } from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { validate } from './utils';
import { RefusalReasonFormView } from './components/refusal-reason-form';
import { removeModalQuery } from '../../utils/removeModalQuery';
import cancelModal from '../../utils/cancelModal';
import closeModal from '../../utils/closeModal';

const RefusalReasonModalWrapper = ({ isModalShown, onSendReason, onResetForm, onFormSubmit, isReasonSending }) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'refusalReasonModalSaga', saga });
	useInjectReducer({ key: 'refusalReasonModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const handleModalCancel = () => {
		cancelModal(query);
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		closeModal(query);
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		onSendReason({
			redirect: handleModalClose,
			id: query.get('id'),
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText="Отклонить дату забора груза"
			modalContent={
				<RefusalReasonFormView
					title="Причина"
					name="comment"
					placeholder="Укажите, пожалуйста, подробно причину..."
					rows={4}
				/>
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'refusal_reason' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleFormSubmit,
							text: 'Отклонить',
							color: 'warning',
							loading: isReasonSending,
							disabled: isReasonSending,
						},
						{
							onClick: handleModalCancel,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(RefusalReasonModalWrapper);

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	isReasonSending: getIsReasonSendingSelector(),
});

const mapDispatchToProps = {
	onSendReason: sendReasonAction,
	onResetForm: reset,
	onFormSubmit: submit,
};

const RefusalReasonModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

RefusalReasonModalWrapper.propTypes = {
	isReasonSending: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onSendReason: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default RefusalReasonModal;
