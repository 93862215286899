/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import saga from './saga';
import reducer from './reducer';
import { getDictionaryDataAction, resetStateAction } from './actions';
import { ModalWindow } from '../../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import { getDictionaryDataSelector, getIsDictionaryDataLoadingSelector, getIsModalShownSelector } from './selectors';
import { TableComponent } from '../../../components/table-component';
import { TABLE_COLUMNS_NAMES } from './constants';
import ModalFooterButtons from '../../../semantic-ui/components/modal-window/modal-footer-buttons';

const CompaniesTypesDictionaryWrapper = ({
	onResetState,
	isModalShown,
	onGetDictionaryData,
	dictionaryData,
	isDictionaryDataLoading,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'companiesTypesDictionarySaga', saga });
	useInjectReducer({ key: 'companiesTypesDictionaryReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) onGetDictionaryData();
	}, [isModalShown]);

	const handleModalClose = () => {
		query.delete('modal');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText="Типы компаний"
			modalContent={
				<TableComponent
					columns={TABLE_COLUMNS_NAMES}
					rows={dictionaryData}
					isTableDataLoading={isDictionaryDataLoading}
					isWithoutHeader
					isWithoutFooter
				/>
			}
			style={{ width: '732px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'companies_types' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleModalClose,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	dictionaryData: getDictionaryDataSelector(),
	isDictionaryDataLoading: getIsDictionaryDataLoadingSelector(),
});

const mapDispatchToProps = {
	onGetDictionaryData: getDictionaryDataAction,
	onResetState: resetStateAction,
};

const CompaniesTypesDictionaryModal = connect(mapStateToProps, mapDispatchToProps)(CompaniesTypesDictionaryWrapper);

CompaniesTypesDictionaryWrapper.propTypes = {
	onGetDictionaryData: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	isDictionaryDataLoading: PropTypes.bool.isRequired,
	dictionaryData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
};

export default CompaniesTypesDictionaryModal;
