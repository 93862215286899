import { URL } from './constants';
import { getToken, makeRequest } from './utils';

// Get claims list
export const getClaimsRequest = (status, searchQuery) =>
	makeRequest(`${URL}/api/claims/${status}${searchQuery ? `?${searchQuery}` : ''}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

// Get claim details
export const getClaimDetailsRequest = (id, req_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

// Get claim documents
export const getClaimDocsRequest = (id, req_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/documents`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getClaimDocsFormRequest = (id, req_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/selectDocuments`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const setClaimDocsRequest = (id, req_id, body) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/setDocuments`, {
		method: 'POST',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		body,
	});

export const getClaimEventsRequest = (id, req_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/events`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const solveClaimRequest = (id, req_id, payload) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/solve`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const agreeClaimRequest = (id, req_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/agree`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
	});

export const rejectClaimRequest = (id, req_id, payload) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${id}/reject`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const createClaimRequest = (req_id, payload) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify(payload),
	});

export const updateClaimRequest = (id, payload) =>
	makeRequest(`${URL}/api/reqs/claims/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify(payload),
	});

export const deleteClaimRequest = id =>
	makeRequest(`${URL}/api/reqs/claims/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getReqClaimsRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/claims`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getClaimThemesRequest = () =>
	makeRequest(`${URL}/api/claims_themes?receive=all`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getClaimCategoriesRequest = themeId =>
	makeRequest(`${URL}/api/claims_themes/${themeId}/categories?receive=all`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getClaimPrioritiesRequest = () =>
	makeRequest(`${URL}/api/claims_priorities?receive=all`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getSelectSolveClaimRequest = (req_id, claim_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${claim_id}/selectSolve`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getSelectRejectClaimRequest = (req_id, claim_id) =>
	makeRequest(`${URL}/api/reqs/${req_id}/claims/${claim_id}/selectReject`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});
