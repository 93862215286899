import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const ClaimsCustomModalCell = ({ rowData }) => {
	if (rowData.id === 'company') {
		return (
			<div className="claims-custom-modal-cell__company-container">
				<span className="claims-custom-modal-cell__company">
					<div className="claims-custom-modal-cell__cell_bold">{rowData.value.name}</div>
					<div className="claims-custom-modal-cell__cell_thin">{rowData.value.type}</div>
				</span>
				<span className="claims-custom-modal-cell__contact">
					<div>{rowData.value.contact.name}</div>
					<div>{rowData.value.contact.email}</div>
					<div>{rowData.value.contact.phone}</div>
				</span>
			</div>
		);
	}

	if (rowData.id === 'contact') {
		const phone = rowData.value.extension
			? `${rowData.value.phone}, ${rowData.value.extension}`
			: rowData.value.phone;

		return (
			<span className="claims-custom-modal-cell__contact">
				<div>{rowData.value.name}</div>
				<div>{rowData.value.email}</div>
				<div>{phone}</div>
			</span>
		);
	}

	if (rowData.id === 'closed_by_user') {
		if (!rowData.values) return null;
		const cellValues = [];
		rowData.values.forEach((value, i) => {
			if (!value) return;
			const phone = value.value.extension ? `${value.value.phone}, ${value.value.extension}` : value.value.phone;
			cellValues.push(
				<>
					<div className="claims-custom-modal-cell__user">
						<div>{value.value.name}</div>
						<div>{phone}</div>
					</div>
					{i === 0 && (
						<div className="claims-custom-modal-cell__status_title">{rowData?.values[1]?.title}</div>
					)}
				</>,
			);
		});
		return <div className="claims-custom-modal-cell__status">{cellValues}</div>;
	}

	return rowData.value?.name || rowData.value || '---';
};

ClaimsCustomModalCell.propTypes = {
	rowData: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.any.isRequired,
		values: PropTypes.array,
	}).isRequired,
};

export { ClaimsCustomModalCell };
