import { call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch, change } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS_CLAIM_FORM } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import {
	GET_EVENT_DATA,
	GET_REQ_DETAILS,
	GET_REQ_DOCS,
	setEventDataAction,
	setIsEventDataLoadingAction,
	setIsReqDetailsLoadingAction,
	setIsReqDocsLoadingAction,
	setReqDetailsAction,
	setReqDocsAction,
	GET_REQ_CLAIMS,
	setReqClaimsAction,
	setIsReqClaimsLoadingAction,
	GET_CLAIM_THEMES,
	GET_CLAIM_CATEGORIES,
	GET_CLAIM_PRIORITIES,
	setClaimThemesAction,
	setClaimCategoriesAction,
	setClaimPrioritiesAction,
	setIsClaimThemesLoadingAction,
	setIsClaimCategoriesLoadingAction,
	setIsClaimPrioritiesLoadingAction,
	CREATE_CLAIM,
	setIsClaimLoadingAction,
} from './actions';
import {
	getReqClaimsRequest,
	getClaimThemesRequest,
	getClaimCategoriesRequest,
	getClaimPrioritiesRequest,
	createClaimRequest,
} from '../../api/claims';
import { getReqDetailsRequest, getReqDocsRequest, getReqEventRequest } from '../../api/requests';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getClaimFormValuesSelector, getReqIdSelector } from './selectors';
import { transformToValidationErrors } from './utils';

export function* getReqsDetailsSaga({ payload: { id, searchQuery } }) {
	try {
		yield put(setIsReqDetailsLoadingAction(true));

		const { data, message, toast } = yield call(getReqDetailsRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			let updateData = data;

			if (data?.offer?.link?.url) {
				updateData = {
					...data,
					offer: {
						...data.offer,
						link: {
							...data.offer.link,
							url: `${data.offer.link.url}${
								removeModalQuery(searchQuery) ? `&${removeModalQuery(searchQuery)}` : ''
							}`,
						},
					},
				};
			}

			yield put(setReqDetailsAction(updateData));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqDetailsLoadingAction(false));
	}
}

export function* getReqsDocsSaga({ id }) {
	try {
		yield put(setIsReqDocsLoadingAction(true));

		const { data, message, toast } = yield call(getReqDocsRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setReqDocsAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqDocsLoadingAction(false));
	}
}

export function* getEventDataSaga({ payload: id }) {
	try {
		yield put(setIsEventDataLoadingAction(true));

		const { message, toast, reports } = yield call(getReqEventRequest, id);

		if (reports) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setEventDataAction(reports?.html || null));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsEventDataLoadingAction(false));
	}
}

export function* getReqClaimsSaga({ id }) {
	try {
		yield put(setIsReqClaimsLoadingAction(true));

		const { data, message } = yield call(getReqClaimsRequest, id);

		if (data) {
			yield put(setReqClaimsAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqClaimsLoadingAction(false));
	}
}

export function* getClaimThemesSaga() {
	try {
		yield put(setIsClaimThemesLoadingAction(true));

		const { data, message } = yield call(getClaimThemesRequest);

		if (data) {
			yield put(setClaimThemesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsClaimThemesLoadingAction(false));
	}
}

export function* getClaimCategoriesSaga({ payload: { themeId } }) {
	try {
		yield put(setIsClaimCategoriesLoadingAction(true));

		const { data, message } = yield call(getClaimCategoriesRequest, themeId);

		if (data) {
			yield put(setClaimCategoriesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsClaimCategoriesLoadingAction(false));
	}
}

export function* getClaimPrioritiesSaga() {
	try {
		yield put(setIsClaimPrioritiesLoadingAction(true));

		const { data, message } = yield call(getClaimPrioritiesRequest);

		if (data) {
			yield put(setClaimPrioritiesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
			const isDefault = data.find(item => item.is_default);
			if (isDefault) {
				yield put(change('claimForm', 'priority_id', isDefault.id));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsClaimPrioritiesLoadingAction(false));
	}
}

export function* createClaimSaga({ payload: { history, query, pathname, closeModal } }) {
	try {
		yield put(setIsClaimLoadingAction(true));
		const body = yield select(getClaimFormValuesSelector());

		const reqId = yield select(getReqIdSelector());

		const { data, message, toast, errors } = yield call(createClaimRequest, reqId, body);

		if (data) {
			yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			history.push(`${pathname}?${query}`);
			closeModal();
		} else {
			if (errors) {
				yield put(touch('claimForm', ...REQUIRED_FIELDS_CLAIM_FORM));
				yield put(stopSubmit('claimForm', transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsClaimLoadingAction(false));
	}
}

export default function* reqsDetailsModalSaga() {
	yield takeEvery(GET_REQ_DETAILS, getReqsDetailsSaga);
	yield takeEvery(GET_REQ_DOCS, getReqsDocsSaga);
	yield takeEvery(GET_EVENT_DATA, getEventDataSaga);
	yield takeEvery(GET_REQ_CLAIMS, getReqClaimsSaga);
	yield takeEvery(GET_CLAIM_THEMES, getClaimThemesSaga);
	yield takeEvery(GET_CLAIM_CATEGORIES, getClaimCategoriesSaga);
	yield takeEvery(GET_CLAIM_PRIORITIES, getClaimPrioritiesSaga);
	yield takeEvery(CREATE_CLAIM, createClaimSaga);
}
