import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import './styles.less';
import { Loader } from 'semantic-ui-react';
import { DOCUMENTS_TAB_ICONS } from '../../constants';
import { FileUploader } from '../../../../semantic-ui/components/file-uploader';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';

const DocumentAttachmentFormView = ({
	documents,
	onFileUploadedChange,
	files,
	onUploadedFileDelete,
	formErrors,
	isFormLoading,
}) => {
	return (
		<div className="document-attachment-form">
			{isFormLoading && <Loader active />}
			{documents.map(item => (
				<div className="document-attachment-form__row" key={item.name}>
					<div className="document-attachment-form__column">
						<div className="document-attachment-form__inner-row">
							<img src={DOCUMENTS_TAB_ICONS[item.icon]} alt={item.icon} />
							<span className="document-attachment-form__document-text document-attachment-form__document-text_bold">
								{item.name}
							</span>
							{item.is_other && (
								<div className="document-attachment-form__document-name">
									<Field
										name="document_name"
										type="text"
										component={FormInputField}
										autoComplete="off"
										placeholder="Название док-та"
									/>
								</div>
							)}
						</div>
						{formErrors[item.key] && (
							<div className="document-attachment-form__error">{formErrors[item.key]}</div>
						)}
					</div>
					<div className="document-attachment-form__files">
						<FileUploader
							links={files[item.key] || []}
							onFileUploadedChange={e => {
								return onFileUploadedChange(e, item.key);
							}}
							onUploadedFileDelete={(_, name) => onUploadedFileDelete(name, item.key)}
							isOnlyOneFileToUpload={!item.is_multiple}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

DocumentAttachmentFormView.propTypes = {
	documents: PropTypes.arrayOf(PropTypes.shape({})),
	files: PropTypes.object,
	onFileUploadedChange: PropTypes.func.isRequired,
	onUploadedFileDelete: PropTypes.func.isRequired,
	formErrors: PropTypes.objectOf(PropTypes.string),
	isFormLoading: PropTypes.bool.isRequired,
};

export { DocumentAttachmentFormView };
