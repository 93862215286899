import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';
import './styles.less';
import { FormTextAreaField } from '../../../../semantic-ui/components/form-textarea-field';

export const RefusalReasonFormView = ({ title, name, placeholder, rows }) => {
	return (
		<div className="refusal-reason-form-wrapper">
			<Grid className="refusal-reason-form">
				<Grid.Row className="refusal-reason-form__row">
					<Grid.Column width={15} className="refusal-reason-form__column">
						{title}
						<div className="refusal-reason-form__column-field">
							<Field
								name={name}
								type="text"
								component={FormTextAreaField}
								autoComplete="off"
								width="100%"
								height="100%"
								resize="vertical"
								rows={rows}
								placeholder={placeholder}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

RefusalReasonFormView.propTypes = {
	title: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	rows: PropTypes.number.isRequired,
};
