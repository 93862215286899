import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { getAddressInfoAction, getPartnerAddressInfoAction, resetStateAction } from './actions';
import { getIsModalShownSelector, getIsPartnersRouteSelector } from './selectors';
import { ContactsContainer } from '../contacts';
import { resetStateAction as resetContactModalState } from '../contact-modal/actions';
import { PartnerAddressModal } from './components/partner-address-modal';
import { AddressModal } from './components/address-modal';

const AddressModalWrapper = ({
	onResetState,
	onResetContactModalState,
	isPartnersRoute,
	onGetAddressInfo,
	isModalShown,
	onGetPartnerAddressInfo,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'addressesModalsSaga', saga });
	useInjectReducer({ key: 'addressesModalsReducer', reducer });

	const { search } = useLocation();

	const query = new URLSearchParams(search);

	const modal = query.get('modal');

	useEffect(() => {
		return () => {
			onResetState();
			onResetContactModalState();
		};
	}, []);

	useEffect(() => {
		if (!isPartnersRoute && query.get('id') && isModalShown) {
			onGetAddressInfo(query.get('id'));
		}

		if (isPartnersRoute && query.get('id') && query.get('addressId') && isModalShown) {
			onGetPartnerAddressInfo({ partnerId: query.get('id'), addressId: query.get('addressId') });
		}
	}, [query.get('id'), query.get('addressId')]);

	if (modal === 'contacts') {
		return <ContactsContainer isChildren />;
	}

	if (isPartnersRoute) {
		return <PartnerAddressModal />;
	}

	return <AddressModal />;
};

const mapStateToProps = createStructuredSelector({
	isPartnersRoute: getIsPartnersRouteSelector(),
	isModalShown: getIsModalShownSelector(),
});

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onResetContactModalState: resetContactModalState,
	onGetAddressInfo: getAddressInfoAction,
	onGetPartnerAddressInfo: getPartnerAddressInfoAction,
};

const AddressModalPage = connect(mapStateToProps, mapDispatchToProps)(AddressModalWrapper);

AddressModalWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	onResetContactModalState: PropTypes.func.isRequired,
	isPartnersRoute: PropTypes.bool.isRequired,
	onGetAddressInfo: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onGetPartnerAddressInfo: PropTypes.func.isRequired,
};

export default AddressModalPage;
