/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import { createDateCargoCollectionAction, getRequestInfoAction, resetStateAction } from './actions';
import {
	getIsButtonsDisabledSelector,
	getIsModalShownSelector,
	getModalStatusesSelector,
	getReqNameSelector,
	getDateCargoFormInitialValuesSelector,
	getFormErrorsSelector,
} from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { DateCargoCollectionModalFormView } from './components/date-cargo-collection-modal-form';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { validate } from './utils';
import cancelModal from '../../utils/cancelModal';
import closeModal from '../../utils/closeModal';

const DateCargoCollectionModalWrapper = ({
	onResetState,
	isModalShown,
	onCreateDateCargoCollection,
	onGetRequestInfo,
	reqName,
	modalStatuses,
	onResetForm,
	isButtonsDisabled,
	onFormSubmit,
	formErrors,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'dateCargoCollectionModalSaga', saga });
	useInjectReducer({ key: 'dateCargoCollectionModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetRequestInfo(query.get('id'));
		}
	}, [isModalShown]);

	const handleModalCancel = () => {
		cancelModal(query);
		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		closeModal(query);
		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleCreateDateButtonClick = async () => {
		await onFormSubmit(FORM_NAME);

		onCreateDateCargoCollection({ redirect: handleModalClose, id: query.get('id'), searchQuery: query.toString() });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleCreateDateButtonClick();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={reqName}
			modalContent={<DateCargoCollectionModalFormView formErrors={formErrors} />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'carrier_change' }]}
			headerStatuses={modalStatuses}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleCreateDateButtonClick,
							text: 'Назначить',
							color: 'primary',
							disabled: isButtonsDisabled,
						},
						{
							onClick: handleModalCancel,
							text: 'Отмена',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(DateCargoCollectionModalWrapper);

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	reqName: getReqNameSelector(),
	modalStatuses: getModalStatusesSelector(),
	isButtonsDisabled: getIsButtonsDisabledSelector(),
	initialValues: getDateCargoFormInitialValuesSelector(),
	formErrors: getFormErrorsSelector(),
});

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onCreateDateCargoCollection: createDateCargoCollectionAction,
	onGetRequestInfo: getRequestInfoAction,
	onResetForm: reset,
	onFormSubmit: submit,
};

const DateCargoCollectionModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

DateCargoCollectionModalWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onCreateDateCargoCollection: PropTypes.func.isRequired,
	onGetRequestInfo: PropTypes.func.isRequired,
	reqName: PropTypes.string.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isButtonsDisabled: PropTypes.bool.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	formErrors: PropTypes.arrayOf(PropTypes.string),
	modalStatuses: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string, name: PropTypes.string })).isRequired,
};

export default DateCargoCollectionModal;
