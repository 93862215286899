import { CustomCell } from '../../components/custom-cell';
import { CustomModalCell } from './components/custom-modal-cell';

export const ADDRESSES_FORM_NAME = 'address-form';

export const REQUIRED_FIELDS = ['name', 'address', 'work_mode'];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const ADDRESSES_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const ADDRESSES_TABLE_ROWS_NAMES = {
	address: 'Адрес',
	work_mode: 'Режим работы',
	contact: 'Контактное лицо',
	comment: 'Примечание',
	postal_code: 'Индекс',
	link: 'Карта',
	author: 'Автор',
};

export const CONTACTS_TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Название', id: 'name', width: '344px', customComponent: CustomCell },
	{ name: 'Адрес', id: 'address', width: '689px' },
];
