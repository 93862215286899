/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import {
	getAddressesAction,
	getPartnerAddressesAction,
	resetStateAction,
	setAddressesSearchValueAction,
} from './actions';
import {
	getAddressesSearchValueSelector,
	getAddressesSelector,
	getIsAddressesLoadingSelector,
	getPaginationSelector,
} from './selectors';
import { TableComponent } from '../../components/table-component';
import { ADDRESSES_TABLE_COLUMNS } from './constants';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { ModalHeader } from './components/modal-header';
import { getIsAvailableAddressesButtonsSelector } from '../profile/selectors';
import { getIsAuditTabShownSelector } from '../partner-modal/selectors';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';

const AddressesContainerWrapper = ({
	onGetAddresses,
	addresses,
	addressesSearchValue,
	onSetAddressesSearchValue,
	onResetState,
	pagination,
	isChildren,
	onGetPartnerAddresses,
	isAvailable,
	isAddressesLoading,
	isAuditTabShown,
}) => {
	useInjectSaga({ key: 'addressesSaga', saga });
	useInjectReducer({ key: 'addressesReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const modal = query.get('modal');

	const id = query.get('id');

	const history = useHistory();

	const getAddresses = isChildren ? onGetPartnerAddresses : onGetAddresses;

	useEffect(() => {
		if (isChildren) {
			onResetState();
			getAddresses({ searchQuery: query.toString(), id });
		}
	}, []);

	useEffect(() => {
		if (!addresses.length) {
			getAddresses({ searchQuery: query.toString(), id });
		}
	}, []);

	useEffect(() => {
		if (query.has('addressesSearch')) {
			onSetAddressesSearchValue(query.get('addressesSearch').toString());
		}
	}, []);

	const handleAddButtonClick = () => {
		query.set('modal', 'address');
		query.set('mode', 'add');

		history.push(`${pathname}?${query}`);
	};

	const handleKeyPress = ({ key }) => {
		if (key === 'Enter') {
			query.set('page', 1);
			if (addressesSearchValue) {
				query.set('addressesSearch', addressesSearchValue);
			} else {
				query.delete('addressesSearch');
			}

			history.push(`${pathname}?${query}`);
			getAddresses({ searchQuery: query.toString(), id });
		}
	};

	const handleSearchInputChange = ({ target: { value } }) => onSetAddressesSearchValue(value);

	const handleSearchInputClear = () => {
		onSetAddressesSearchValue('');

		query.delete('addressesSearch');
		history.push(`${pathname}?${query}`);
		getAddresses({ searchQuery: query.toString(), id });
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		getAddresses({ searchQuery: query.toString(), id });
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
			history.push(`${pathname}?${query}`);
		} else {
			query.set('per_page', value);
			history.push(`${pathname}?${query}`);
		}
		getAddresses({ searchQuery: query.toString(), id });
	};

	const handleNavItemClick = (modal, tab) => {
		query.set('modal', modal);

		if (tab) {
			query.set('tab', tab);
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('contactsSearch');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleDownloadAddressesButtonClick = () => {
		window.open(`/api/partners/${id}/addresses/export`, '_blank');
	};

	if (isChildren) {
		return (
			<ModalWindow
				isModalShown
				navItems={[
					{
						id: 'info',
						text: 'Информация',
						onClick: () => handleNavItemClick('partner'),
						isActive: modal === 'partner',
					},
					{
						id: 'addresses',
						text: 'Адреса',
						onClick: () => handleNavItemClick('addresses'),
						isActive: modal === 'addresses',
					},
					{
						id: 'audit',
						text: 'Изменения',
						onClick: () => handleNavItemClick('partner', 'audit'),
						isActive: modal === 'partner' && isAuditTabShown,
					},
				]}
				modalContent={
					<TableComponent
						headerText="Адреса"
						onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
						columns={ADDRESSES_TABLE_COLUMNS}
						rows={addresses}
						onSearch={handleKeyPress}
						isTableDataLoading={isAddressesLoading}
						pagination={
							isChildren
								? null
								: {
										...pagination,
										onPageChange: handlePageChange,
										onSelectChange: handleSelectChange,
								  }
						}
						searchInputValue={addressesSearchValue}
						onSearchInputChange={handleSearchInputChange}
						onSearchInputClear={handleSearchInputClear}
						isChildren={isChildren}
						isWithoutFooter={isChildren}
					/>
				}
				modalHeader={
					<ModalHeader
						onKeyPress={handleKeyPress}
						searchInputValue={addressesSearchValue}
						onSearchInputChange={handleSearchInputChange}
						onSearchInputClear={handleSearchInputClear}
						onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
						onDownloadButtonClick={isAvailable.export ? handleDownloadAddressesButtonClick : null}
						onModalClose={handleModalClose}
					/>
				}
				footerButtons={
					<ModalFooterButtons
						rightButtons={[
							{
								onClick: handleModalClose,
								text: 'Закрыть',
								color: 'secondary',
							},
						]}
					/>
				}
				style={{ width: '900px' }}
			/>
		);
	}

	const handleDownloadButtonClick = () => {
		window.open('/api/addresses/export', '_blank');
	};

	return (
		<TableComponent
			headerText="Адреса"
			onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
			onDownloadButtonClick={isAvailable.export ? handleDownloadButtonClick : null}
			columns={ADDRESSES_TABLE_COLUMNS}
			rows={addresses}
			onSearch={handleKeyPress}
			isTableDataLoading={isAddressesLoading}
			pagination={
				!pagination
					? null
					: { ...pagination, onPageChange: handlePageChange, onSelectChange: handleSelectChange }
			}
			searchInputValue={addressesSearchValue}
			onSearchInputChange={handleSearchInputChange}
			onSearchInputClear={handleSearchInputClear}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	addresses: getAddressesSelector(),
	addressesSearchValue: getAddressesSearchValueSelector(),
	pagination: getPaginationSelector(),
	isAvailable: getIsAvailableAddressesButtonsSelector(),
	isAddressesLoading: getIsAddressesLoadingSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetAddresses: getAddressesAction,
	onSetAddressesSearchValue: setAddressesSearchValueAction,
	onResetState: resetStateAction,
	onGetPartnerAddresses: getPartnerAddressesAction,
};

AddressesContainerWrapper.propTypes = {
	onGetAddresses: PropTypes.func.isRequired,
	addresses: PropTypes.array.isRequired,
	addressesSearchValue: PropTypes.string.isRequired,
	onSetAddressesSearchValue: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	isChildren: PropTypes.bool,
	onGetPartnerAddresses: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	isAddressesLoading: PropTypes.bool.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
};

const AddressesContainer = connect(mapStateToProps, mapDispatchToProps)(AddressesContainerWrapper);

export default AddressesContainer;
