import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import './styles.less';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { FormTextAreaField } from '../../../../../semantic-ui/components/form-textarea-field';

export const ClaimFormView = ({
	themes,
	categories,
	priorities,
	isThemesLoading,
	isCategoriesLoading,
	isPrioritiesLoading,
	isClaimLoading,
}) => {
	return (
		<Grid className="claim-form">
			<div className="claim-form__loader">
				{isClaimLoading && <Loader active inline="centered" size="large" />}
			</div>
			<Grid.Row className="claim-form__row">
				<Grid.Column width={6} className="claim-form__column">
					Тема
				</Grid.Column>
				<Grid.Column width={7} className="claim-form__column">
					<Field
						name="theme_id"
						component={FormSelectField}
						options={themes}
						search
						dropdownClassName="static"
						loading={isThemesLoading}
						noResultsMessage="Не найдено"
						placeholder="Выберите тему"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="claim-form__row">
				<Grid.Column width={6} className="claim-form__column">
					Категория
				</Grid.Column>
				<Grid.Column width={7} className="claim-form__column">
					<Field
						name="category_id"
						component={FormSelectField}
						options={categories}
						search
						dropdownClassName="static"
						loading={isCategoriesLoading}
						noResultsMessage="Выберите тему"
						placeholder="Выберите категорию"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="claim-form__row">
				<Grid.Column width={6} className="claim-form__column">
					Приоритет
				</Grid.Column>
				<Grid.Column width={7} className="claim-form__column">
					<Field
						name="priority_id"
						component={FormSelectField}
						options={priorities}
						search
						dropdownClassName="static"
						loading={isPrioritiesLoading}
						noResultsMessage="Не найдено"
						placeholder="Выберите приоритет"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="claim-form__row">
				<Grid.Column width={6} className="claim-form__column">
					Проблема
				</Grid.Column>
				<Grid.Column width={7} className="claim-form__column">
					<Field
						name="problem"
						component={FormTextAreaField}
						type="text"
						autoComplete="off"
						placeholder="Текст проблемы"
						width="100%"
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

ClaimFormView.propTypes = {
	themes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	priorities: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isThemesLoading: PropTypes.bool.isRequired,
	isCategoriesLoading: PropTypes.bool.isRequired,
	isPrioritiesLoading: PropTypes.bool.isRequired,
	isClaimLoading: PropTypes.bool.isRequired,
};
