import { WaysCustomCell } from './components/ways-custom-cell';

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: WaysCustomCell },
	{ name: 'Название', id: 'name', width: '20%', customComponent: WaysCustomCell },
	{ name: 'Адрес отгрузки', id: 'address_from', width: '40%', customComponent: WaysCustomCell },
	{ name: 'Адрес доставки', id: 'address_to', width: '40%', customComponent: WaysCustomCell },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
