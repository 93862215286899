export const formatTime = seconds => {
	const hours = Math.floor(seconds / 3600);

	const minutes = Math.floor((seconds % 3600) / 60);

	const secs = seconds % 60;

	const pad = num => (num < 10 ? `0${num}` : String(num));

	return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export const redirectToExport = ids => {
	const pathname = window.location.pathname;

	const exportUrl = `/api${pathname}/export`;

	const currentParams = new URLSearchParams(window.location.search);

	let queryParams = '';

	if (ids.length > 0) {
		currentParams.set('filter.ids', ids.join(','));
	}

	if (currentParams.toString()) {
		queryParams = `?${currentParams.toString()}`;
	}

	window.open(exportUrl + queryParams, '_blank');
};
