/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { ContactModalView } from './contact-modal-view';
import { ADD_CONTACT_FORM_NAME } from '../../constants';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import { createContactAction } from '../../actions';
import { validateContactForm } from '../../utils';

const ContactModalWrapper = ({ onCreateContact, onFormSubmit }) => {
	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const handleModalClose = () => {
		query.set('modal', 'request');

		history.push(`${pathname}?${query}`);
	};

	const handleContactFormSubmit = async () => {
		await onFormSubmit(ADD_CONTACT_FORM_NAME);

		onCreateContact({ redirect: handleModalClose });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleContactFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown
			onKeyDown={handleKeyDown}
			headerText="Создать контактное лицо"
			modalContent={<ContactModalView />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'profile' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={[]}
					rightButtons={[
						{ onClick: handleContactFormSubmit, text: 'Сохранить', color: 'primary' },
						{ onClick: handleModalClose, text: 'Отмена', color: 'secondary' },
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: ADD_CONTACT_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validateContactForm,
})(ContactModalWrapper);

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
	onFormSubmit: submit,
	onCreateContact: createContactAction,
};

const ContactModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

ContactModalWrapper.propTypes = {
	onCreateContact: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export { ContactModal };
