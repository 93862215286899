export const GET_REQ_DETAILS = 'reqs-details-modal/GET_REQ_DETAILS';
export const getReqDetailsAction = payload => ({
	type: GET_REQ_DETAILS,
	payload,
});

export const SET_REQ_DETAILS = 'reqs-details-modal/SET_REQ_DETAILS';
export const setReqDetailsAction = payload => ({
	type: SET_REQ_DETAILS,
	payload,
});

export const SET_IS_REQ_DETAILS_LOADING = 'reqs-details-modal/SET_IS_REQ_DETAILS_LOADING';
export const setIsReqDetailsLoadingAction = payload => ({
	type: SET_IS_REQ_DETAILS_LOADING,
	payload,
});

export const GET_REQ_DOCS = 'reqs-details-modal/GET_REQ_DOCS';
export const getReqDocsAction = id => ({
	type: GET_REQ_DOCS,
	id,
});

export const SET_REQ_DOCS = 'reqs-details-modal/SET_REQ_DOCS';
export const setReqDocsAction = payload => ({
	type: SET_REQ_DOCS,
	payload,
});

export const SET_IS_REQ_DOCS_LOADING = 'reqs-details-modal/SET_IS_REQ_DOCS_LOADING';
export const setIsReqDocsLoadingAction = payload => ({
	type: SET_IS_REQ_DOCS_LOADING,
	payload,
});

export const GET_EVENT_DATA = 'reqs-details-modal/GET_EVENT_DATA';
export const getEventDataAction = payload => ({
	type: GET_EVENT_DATA,
	payload,
});

export const SET_EVENT_DATA = 'reqs-details-modal/SET_EVENT_DATA';
export const setEventDataAction = payload => ({
	type: SET_EVENT_DATA,
	payload,
});

export const SET_IS_EVENT_DATA_LOADING = 'reqs-details-modal/SET_IS_EVENT_DATA_LOADING';
export const setIsEventDataLoadingAction = payload => ({
	type: SET_IS_EVENT_DATA_LOADING,
	payload,
});

export const RESET_STATE = 'reqs-details-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_REQ_CLAIMS = 'reqs-details-modal/GET_REQ_CLAIMS';
export const getReqClaimsAction = id => ({
	type: GET_REQ_CLAIMS,
	id,
});

export const SET_REQ_CLAIMS = 'reqs-details-modal/SET_REQ_CLAIMS';
export const setReqClaimsAction = payload => ({
	type: SET_REQ_CLAIMS,
	payload,
});

export const SET_IS_REQ_CLAIMS_LOADING = 'reqs-details-modal/SET_IS_REQ_CLAIMS_LOADING';
export const setIsReqClaimsLoadingAction = payload => ({
	type: SET_IS_REQ_CLAIMS_LOADING,
	payload,
});

export const CREATE_CLAIM = 'reqs-details-modal/CREATE_CLAIM';
export const createClaimAction = payload => ({
	type: CREATE_CLAIM,
	payload,
});

export const SET_IS_CREATE_CLAIM_LOADING = 'reqs-details-modal/SET_IS_CREATE_CLAIM_LOADING';
export const setIsCreateClaimLoadingAction = payload => ({
	type: SET_IS_CREATE_CLAIM_LOADING,
	payload,
});

export const GET_CLAIM_THEMES = 'reqs-details-modal/GET_CLAIM_THEMES';
export const getClaimThemesAction = () => ({
	type: GET_CLAIM_THEMES,
});

export const SET_CLAIM_THEMES = 'reqs-details-modal/SET_CLAIM_THEMES';
export const setClaimThemesAction = payload => ({
	type: SET_CLAIM_THEMES,
	payload,
});

export const SET_IS_CLAIM_THEMES_LOADING = 'reqs-details-modal/SET_IS_CLAIM_THEMES_LOADING';
export const setIsClaimThemesLoadingAction = payload => ({
	type: SET_IS_CLAIM_THEMES_LOADING,
	payload,
});

export const GET_CLAIM_CATEGORIES = 'reqs-details-modal/GET_CLAIM_CATEGORIES';
export const getClaimCategoriesAction = payload => ({
	type: GET_CLAIM_CATEGORIES,
	payload,
});

export const SET_CLAIM_CATEGORIES = 'reqs-details-modal/SET_CLAIM_CATEGORIES';
export const setClaimCategoriesAction = payload => ({
	type: SET_CLAIM_CATEGORIES,
	payload,
});

export const SET_IS_CLAIM_CATEGORIES_LOADING = 'reqs-details-modal/SET_IS_CLAIM_CATEGORIES_LOADING';
export const setIsClaimCategoriesLoadingAction = payload => ({
	type: SET_IS_CLAIM_CATEGORIES_LOADING,
	payload,
});

export const GET_CLAIM_PRIORITIES = 'reqs-details-modal/GET_CLAIM_PRIORITIES';
export const getClaimPrioritiesAction = () => ({
	type: GET_CLAIM_PRIORITIES,
});

export const SET_CLAIM_PRIORITIES = 'reqs-details-modal/SET_CLAIM_PRIORITIES';
export const setClaimPrioritiesAction = payload => ({
	type: SET_CLAIM_PRIORITIES,
	payload,
});

export const SET_IS_CLAIM_PRIORITIES_LOADING = 'reqs-details-modal/SET_IS_CLAIM_PRIORITIES_LOADING';
export const setIsClaimPrioritiesLoadingAction = payload => ({
	type: SET_IS_CLAIM_PRIORITIES_LOADING,
	payload,
});

export const SET_IS_CLAIM_LOADING = 'reqs-details-modal/SET_IS_CLAIM_LOADING';
export const setIsClaimLoadingAction = payload => ({
	type: SET_IS_CLAIM_LOADING,
	payload,
});

export const RESET_CLAIM_FORM = 'reqs-details-modal/RESET_CLAIM_FORM';
export const resetClaimFormAction = () => ({
	type: RESET_CLAIM_FORM,
});
