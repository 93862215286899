/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { DetailModalContent } from './components/detail-modal-content';
import {
	createDetailAction,
	deleteDetailAction,
	getDetailInfoAction,
	resetStateAction,
	setDetailSearchValueAction,
	setDetailsOptionsAction,
	updateDetailAction,
} from './actions';
import {
	getActiveDetailIdSelector,
	getDetailInfoSelector,
	getDetailNameSelector,
	getIsAuditTabShownSelector,
	getIsDetailAddFormOpenSelector,
	getIsDetailEditFormOpenSelector,
	getIsDetailInfoLoadingSelector,
	getIsModalShownSelector,
} from './selectors';
import { DETAIL_FORM_NAME } from './constants';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableDetailsButtonsSelector } from '../profile/selectors';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const DetailModalWrapper = ({
	onGetDetailInfo,
	isDetailInfoLoading,
	isDetailEditFormOpen,
	isDetailAddFormOpen,
	detailInfo,
	detailName,
	onResetState,
	onDeleteDetail,
	isModalShown,
	activeDetailId,
	onCreateDetail,
	onUpdateDetail,
	onFormSubmit,
	onSetDetailSearchValue,
	onSetDetailsOptions,
	isAvailable,
	onSetWindowInfo,
	isAuditTabShown,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'detailModalPageSaga', saga });
	useInjectReducer({ key: 'detailModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activeDetailId) onGetDetailInfo(activeDetailId);
	}, [activeDetailId]);

	const handleDetailFormOpenButtonClick = mode => {
		if (activeDetailId) {
			query.set('id', activeDetailId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			onSetDetailSearchValue('');
			onSetDetailsOptions([]);
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeDetailId) {
			onResetState();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleDetailDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteDetail({ id: activeDetailId, redirect: handleModalClose }),
			},
		});
	};

	const handleDetailFormSubmit = async () => {
		await onFormSubmit(DETAIL_FORM_NAME);

		if (activeDetailId) {
			onUpdateDetail({
				id: activeDetailId,
				redirect: () => handleDetailFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateDetail({
			redirect: handleModalClose,
			resetState: onResetState,
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleDetailFormSubmit();
		}
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isDetailEditFormOpen || isDetailAddFormOpen
					? `${isDetailEditFormOpen ? 'Редактор реквизитов' : 'Новые реквизиты'}`
					: detailName
			}
			navItems={
				isDetailEditFormOpen || isDetailAddFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<DetailModalContent
						detailInfo={detailInfo}
						isDetailInfoLoading={isDetailInfoLoading}
						isDetailFormOpen={isDetailEditFormOpen || isDetailAddFormOpen}
					/>
				) : (
					<Audit entityName="details" entityId={activeDetailId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'profile' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isDetailEditFormOpen || isDetailAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleDetailDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isDetailInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isDetailEditFormOpen || isDetailAddFormOpen
							? [
									{
										onClick: handleDetailFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isDetailInfoLoading,
									},
									{
										onClick: () => handleDetailFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isDetailInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleDetailFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isDetailInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	detailInfo: getDetailInfoSelector(),
	isDetailInfoLoading: getIsDetailInfoLoadingSelector(),
	isDetailAddFormOpen: getIsDetailAddFormOpenSelector(),
	isDetailEditFormOpen: getIsDetailEditFormOpenSelector(),
	detailName: getDetailNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeDetailId: getActiveDetailIdSelector(),
	isAvailable: getIsAvailableDetailsButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetDetailInfo: getDetailInfoAction,
	onDeleteDetail: deleteDetailAction,
	onResetState: resetStateAction,
	onCreateDetail: createDetailAction,
	onUpdateDetail: updateDetailAction,
	onFormSubmit: submit,
	onSetDetailSearchValue: setDetailSearchValueAction,
	onSetDetailsOptions: setDetailsOptionsAction,
	onSetWindowInfo: setWindowInfoAction,
};

const DetailModal = connect(mapStateToProps, mapDispatchToProps)(DetailModalWrapper);

DetailModalWrapper.propTypes = {
	onGetDetailInfo: PropTypes.func.isRequired,
	detailInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isDetailInfoLoading: PropTypes.bool.isRequired,
	isDetailEditFormOpen: PropTypes.bool.isRequired,
	isDetailAddFormOpen: PropTypes.bool.isRequired,
	detailName: PropTypes.string.isRequired,
	onDeleteDetail: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeDetailId: PropTypes.string,
	onCreateDetail: PropTypes.func.isRequired,
	onUpdateDetail: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSetDetailSearchValue: PropTypes.func.isRequired,
	onSetDetailsOptions: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
};

export default DetailModal;
