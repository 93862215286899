/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { ADD_CAR_FORM_NAME } from '../../constants';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import {
	getAddCarFormInitialValuesSelector,
	getIsAddCarModalShownSelector,
	getIsCarSavingSelector,
	getIsTonnagesLoadingSelector,
	getIsTrailersListLoadingSelector,
	getTonnagesSelector,
	getTrailersListSelector,
} from '../../selectors';
import { createCarAction, getTonnagesAction, getTrailersListAction } from '../../actions';
import { removeModalQuery } from '../../../../utils/removeModalQuery';
import { validateCarForm } from '../../utils';
import { CarFormView } from './car-form-view';

const CarModalWrapper = ({
	isModalShown,
	onCreateCar,
	trailersList,
	isTrailersListLoading,
	onGetTrailersList,
	onClearForm,
	onFormSubmit,
	isCarSaving,
	tonnages,
	isTonnagesLoading,
	onGetTonnages,
}) => {
	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetTrailersList();
			onGetTonnages();
		}
	}, [isModalShown]);

	const handleModalClose = () => {
		query.set('modal', 'car_change');

		onClearForm();
		history.push(`${pathname}?${query}`);
	};

	const handleCarFormSubmit = async () => {
		await onFormSubmit(ADD_CAR_FORM_NAME);

		onCreateCar({
			redirect: handleModalClose,
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleAddTrailerButtonClick = () => {
		query.set('modal', 'add-trailer-modal');

		history.push(`${pathname}?${query}`);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleCarFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText="Создать машину"
			modalContent={
				<CarFormView
					trailersList={trailersList}
					isTrailersListLoading={isTrailersListLoading}
					onAddTrailerButtonClick={handleAddTrailerButtonClick}
					tonnages={tonnages}
					isTonnagesLoading={isTonnagesLoading}
				/>
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'add-car' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleCarFormSubmit,
							text: 'Сохранить',
							color: 'primary',
							disabled: isCarSaving,
						},
						{
							onClick: handleModalClose,
							text: 'Отмена',
							color: 'secondary',
							disabled: isCarSaving,
						},
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: ADD_CAR_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validateCarForm,
})(CarModalWrapper);

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsAddCarModalShownSelector(),
	initialValues: getAddCarFormInitialValuesSelector(),
	trailersList: getTrailersListSelector(),
	isTrailersListLoading: getIsTrailersListLoadingSelector(),
	isCarSaving: getIsCarSavingSelector(),
	tonnages: getTonnagesSelector(),
	isTonnagesLoading: getIsTonnagesLoadingSelector(),
});

const mapDispatchToProps = {
	onCreateCar: createCarAction,
	onGetTrailersList: getTrailersListAction,
	onClearForm: () => reset(ADD_CAR_FORM_NAME),
	onFormSubmit: submit,
	onGetTonnages: getTonnagesAction,
};

const CarModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

CarModalWrapper.propTypes = {
	isModalShown: PropTypes.bool.isRequired,
	onCreateCar: PropTypes.func.isRequired,
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	onGetTrailersList: PropTypes.func.isRequired,
	onClearForm: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isCarSaving: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	onGetTonnages: PropTypes.func.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};

export { CarModal };
