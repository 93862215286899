import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.less';

const ReqsClaimsModalCell = ({ rowData, columnId }) => {
	if (columnId === 'uuid') {
		return (
			<div className="reqs-claims-modal-cell__uuid" key={columnId}>
				<Link to={rowData.uuid.url} key={columnId}>
					{rowData.uuid.text}
				</Link>
			</div>
		);
	}

	if (columnId === 'contact') {
		return (
			<div className="reqs-claims-modal-cell__contact" key={columnId}>
				<div className="reqs-claims-modal-cell__contact-name">{rowData.contact.name}</div>
				{rowData.contact.phone && (
					<div className="reqs-claims-modal-cell__contact-phone">{rowData.contact.phone}</div>
				)}
			</div>
		);
	}

	return rowData[columnId] || '---';
};

ReqsClaimsModalCell.propTypes = {
	rowData: PropTypes.shape({
		uuid: PropTypes.shape({
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			url: PropTypes.string.isRequired,
		}),
		contact: PropTypes.shape({
			name: PropTypes.string,
			phone: PropTypes.string,
		}),
	}).isRequired,
	columnId: PropTypes.string.isRequired,
};

export { ReqsClaimsModalCell };
