import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { initialState } from './reducer';
import { CLAIMS_DOCUMENTS_FORM_NAME, CLAIMS_REJECT_FORM_NAME, CLAIMS_SOLUTION_FORM_NAME } from './constants';

const getRouterLocationsQuery = state => state.router.location.query || {};

export const getIsModalShownSelector = () =>
	createSelector(getRouterLocationsQuery, query => query.modal === 'claims_details');

export const getIsClaimSolveModalShownSelector = () =>
	createSelector(getRouterLocationsQuery, query => query.modal === 'claims_solve');

export const getIsClaimDocumentsModalShownSelector = () =>
	createSelector(getRouterLocationsQuery, query => query.modal === 'claims_docs_attachment');

export const getIsDocsTabShownSelector = () => createSelector(getRouterLocationsQuery, query => query.tab === 'docs');

export const getIsEventsTabShownSelector = () =>
	createSelector(getRouterLocationsQuery, query => query.tab === 'event');

export const getActiveClaimIdSelector = () => createSelector(getRouterLocationsQuery, query => query.id || '');

export const getActiveReqIdSelector = () => createSelector(getRouterLocationsQuery, query => query.req_id || '');

export const getClaimsDataSelector = state => state?.claimsDetailsModalReducer?.data || initialState.data;

export const getClaimNameSelector = () =>
	createSelector(getClaimsDataSelector, data => {
		if (!data) return '';
		return `Претензия №${data.id} от ${new Date(data.created_at).toLocaleDateString('ru-RU')} ${new Date(
			data.created_at,
		).toLocaleTimeString('ru-RU')}`;
	});

export const getIsClaimDetailsLoadingSelector = () => state =>
	state?.claimsDetailsModalReducer?.isClaimDetailsLoading || initialState.isClaimDetailsLoading;

export const getClaimDetailsSelector = () =>
	createSelector(getClaimsDataSelector, data => {
		if (!data) return [];

		return [
			{ id: 'theme', title: 'Тема', value: data.theme?.name },
			{ id: 'category', title: 'Категория', value: data.category?.name },
			{ id: 'priority', title: 'Приоритет', value: data.priority?.name },
			{
				id: 'company',
				title: 'Компания',
				value: {
					name: data?.company?.name || '-',
					type: data?.company?.type?.name || '-',
					contact: {
						name: data?.contact?.name || '-',
						email: data?.contact?.email || '-',
						phone: data?.contact?.extension
							? `${data?.contact?.phone || '-'}, ${data.contact.extension}`
							: data?.contact?.phone || '-',
					},
				},
			},
			{ id: 'problem', title: 'Проблема', value: data.problem },
			{ id: 'req', title: 'Заявка', value: { name: data.req.id } },
			data.solution && { id: 'solution', title: 'Решение', value: data.solution },
			{ id: 'status', title: 'Статус', value: data.status?.name },
			data.closed_by_user && {
				id: 'closed_by_user',
				title: 'Закрыл претензию',
				values: [
					{
						value: {
							name: data.closed_by_user.name,
							phone: data.closed_by_user.extension
								? `${data.closed_by_user.phone}, ${data.closed_by_user.extension}`
								: data.closed_by_user.phone,
						},
					},
					data.agreed_by_user && {
						title: 'Согласовал претензию',
						value: {
							name: data.agreed_by_user.name,
							phone: data.agreed_by_user.extension
								? `${data.agreed_by_user.phone}, ${data.agreed_by_user.extension}`
								: data.agreed_by_user.phone,
						},
					},
				],
			},
		].filter(Boolean);
	});

export const getIsClaimDocsLoadingSelector = () => state =>
	state?.claimsDetailsModalReducer?.isClaimDocsLoading || initialState.isClaimDocsLoading;

export const getClaimDocsSelector = () => state =>
	state?.claimsDetailsModalReducer?.claimDocs || initialState.claimDocs;

export const getClaimsFormSelector = () => state =>
	state?.claimsDetailsModalReducer?.claimsForm || initialState.claimsForm;

export const getIsClaimsFormLoadingSelector = () => state =>
	state?.claimsDetailsModalReducer?.isClaimsFormLoading || initialState.isClaimsFormLoading;

export const getIsClaimEventsLoadingSelector = () => state =>
	state?.claimsDetailsModalReducer?.isClaimEventsLoading || initialState.isClaimEventsLoading;

export const getClaimEventsSelector = () => state =>
	state?.claimsDetailsModalReducer?.claimEvents || initialState.claimEvents;

export const getActionsSelector = () => state =>
	state?.claimsDetailsModalReducer?.data?.actions || initialState.actions;

export const getClaimDocumentsSelector = () => state =>
	state?.claimsDetailsModalReducer?.claimDocs || initialState.claimDocs;

export const getClaimsFilesSelector = () => state => state?.claimsDetailsModalReducer?.files || initialState.files;

export const getClaimDocumentsErrorsSelector = () => state =>
	state?.claimsDetailsModalReducer?.claimDocsFormErrors || initialState.claimDocsFormErrors;

export const isClaimDocsLoadingSelector = () => state =>
	state?.claimsDetailsModalReducer?.isClaimDocsLoading || initialState.isClaimDocsLoading;

export const getFormClaimsSolutionValuesSelector = () =>
	createSelector(getFormValues(CLAIMS_SOLUTION_FORM_NAME), formValues => ({
		...formValues,
	}));

export const getIsClaimRejectModalShownSelector = () =>
	createSelector(getRouterLocationsQuery, query => query.modal === 'claims_reject');

export const getFormClaimsRejectValuesSelector = () => state => getFormValues(CLAIMS_REJECT_FORM_NAME)(state) || {};

export const getDataForSaveSelector = () =>
	createSelector(getClaimsFilesSelector(), getFormValues(CLAIMS_DOCUMENTS_FORM_NAME), (files, formValues) => {
		const formData = new FormData();

		Object.entries(files || {}).forEach(([key, value]) => {
			value.forEach(file => {
				formData.append(`${key}[]`, file.file);
			});
		});
		if (formValues?.document_name) {
			formData.append('document_name', formValues.document_name);
		}

		return formData;
	});
