import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import { touch, stopSubmit, change } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import {
	GET_CLAIM_DETAILS,
	GET_CLAIM_DOCS,
	GET_CLAIM_EVENTS,
	SOLVE_CLAIM,
	AGREE_CLAIM,
	REJECT_CLAIM,
	setClaimDetailsAction,
	setClaimsFormAction,
	GET_CLAIMS_FORM,
	setClaimEventsAction,
	setClaimDocsErrorsAction,
	setDocsFilesAction,
	setClaimDocsAction,
	POST_CLAIM_DOCS,
	GET_SELECT_SOLVE_CLAIM,
	GET_SELECT_REJECT_CLAIM,
} from './actions';
import {
	getClaimDetailsRequest,
	getClaimEventsRequest,
	solveClaimRequest,
	agreeClaimRequest,
	rejectClaimRequest,
	getClaimDocsFormRequest,
	setClaimDocsRequest,
	getClaimDocsRequest,
	getSelectSolveClaimRequest,
	getSelectRejectClaimRequest,
} from '../../api/claims';
import { errorHandler } from '../../api/utils';
import { REQUEST_ERROR_MESSAGE } from '../reqs-details-modal/constants';
import { CLAIMS_REJECT_FORM_NAME, CLAIMS_SOLUTION_FORM_NAME } from './constants';
import { transformToValidationErrors } from '../reqs-details-modal/utils';
import { notificationInit } from '../../modules/notifications/actions';
import { DISMISS_TIME } from '../../api/constants';
import { getDataForSaveSelector } from './selectors';

function* getClaimDetails({ payload }) {
	try {
		const { id, req_id } = payload;

		const response = yield call(getClaimDetailsRequest, id, req_id);
		if (response.data) {
			yield put(setClaimDetailsAction(response.data));
		} else {
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* getClaimDocs({ payload }) {
	try {
		const { id, req_id } = payload;

		const response = yield call(getClaimDocsRequest, id, req_id);

		if (response.data) {
			yield put(setClaimDocsAction(response.data));
		} else {
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* getClaimsForm({ payload }) {
	try {
		const { id, req_id } = payload;

		const response = yield call(getClaimDocsFormRequest, id, req_id);
		if (response.data) {
			yield put(setClaimsFormAction(response.data));
		} else {
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* postClaimDocs({ payload }) {
	try {
		const { id, req_id, callback } = payload;

		const dataForSave = yield select(getDataForSaveSelector());

		const response = yield call(setClaimDocsRequest, id, req_id, dataForSave);
		if (response.data) {
			yield put(setClaimDetailsAction(response.data));
			yield put(setDocsFilesAction({}));
			callback();
			if (response.toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...response.toast }));
			}
		} else {
			if (response.errors) {
				yield put(setClaimDocsErrorsAction(transformToValidationErrors(response.errors)));
			}
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* getClaimEvents({ payload: { id, req_id } }) {
	try {
		const response = yield call(getClaimEventsRequest, id, req_id);
		if (response.reports) {
			yield put(setClaimEventsAction(response.reports.html));
		} else {
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* getSelectSolveClaim({ payload }) {
	try {
		const { id, req_id } = payload;

		const response = yield call(getSelectSolveClaimRequest, req_id, id);
		if (response.data) {
			yield put(change(CLAIMS_SOLUTION_FORM_NAME, 'solution', response.data.solution));
		}
		if (response.message) {
			throw new Error(response.message);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* getSelectRejectClaim({ payload }) {
	try {
		const { id, req_id } = payload;

		const response = yield call(getSelectRejectClaimRequest, req_id, id);
		if (response.data) {
			yield put(change(CLAIMS_REJECT_FORM_NAME, 'reason', response.data.reason));
		}
		if (response.message) {
			throw new Error(response.message);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* solveClaim({ payload }) {
	try {
		const { id, req_id, body, redirect } = payload;

		const response = yield call(solveClaimRequest, id, req_id, body);
		if (response.data) {
			if (response.toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...response.toast }));
			}
			yield put(setClaimDetailsAction(response.data));
			redirect();
		} else {
			if (response.errors) {
				yield put(touch(CLAIMS_SOLUTION_FORM_NAME, ['solution']));
				yield put(stopSubmit(CLAIMS_SOLUTION_FORM_NAME, transformToValidationErrors(response.errors)));
			}
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* agreeClaim({ payload }) {
	try {
		const { id, req_id } = payload;

		const response = yield call(agreeClaimRequest, id, req_id);
		if (response.data) {
			if (response.toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...response.toast }));
			}
			yield put(setClaimDetailsAction(response.data));
		} else {
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

function* rejectClaim({ payload }) {
	try {
		const { id, req_id, body, redirect } = payload;

		const response = yield call(rejectClaimRequest, id, req_id, body);
		if (response.data) {
			if (response.toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...response.toast }));
			}
			yield put(setClaimDetailsAction(response.data));
			redirect();
		} else {
			if (response.errors) {
				yield put(touch(CLAIMS_REJECT_FORM_NAME, ['reason']));
				yield put(stopSubmit(CLAIMS_REJECT_FORM_NAME, transformToValidationErrors(response.errors)));
			}
			throw new Error(response.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export default function* claimsDetailsModalSaga() {
	yield takeLatest(GET_CLAIM_DETAILS, getClaimDetails);
	yield takeLatest(GET_CLAIM_DOCS, getClaimDocs);
	yield takeLatest(POST_CLAIM_DOCS, postClaimDocs);
	yield takeLatest(GET_CLAIMS_FORM, getClaimsForm);
	yield takeLatest(GET_CLAIM_EVENTS, getClaimEvents);
	yield takeLatest(SOLVE_CLAIM, solveClaim);
	yield takeLatest(AGREE_CLAIM, agreeClaim);
	yield takeLatest(REJECT_CLAIM, rejectClaim);
	yield takeLatest(GET_SELECT_SOLVE_CLAIM, getSelectSolveClaim);
	yield takeLatest(GET_SELECT_REJECT_CLAIM, getSelectRejectClaim);
}
