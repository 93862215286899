/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { Grid, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './styles.less';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import locationIcon from '../../../../components/icons/location-icon.svg';
import locationIconSuccess from '../../../../components/icons/location-icon-success.svg';
import checkIcon from '../../../../components/icons/check-icon.svg';
import checkIconChecked from '../../../../components/icons/check-icon-checked.svg';
import { Badge } from '../../../../components/badge';
import { thousandSeparator } from '../../../reqs-filter/utils';
import { Status } from '../../../../components/status';
import { GradusIcon, KgIcon, PieceIcon, VolumeIcon } from '../../../../components/text-icon';
import { getFromStorage } from '../../../../components/sidebar/utils';

export const ReqItemComponent = ({ item }) => {
	let popupPrrContent = item.description;
	if (!item.is_prr) {
		popupPrrContent = 'Без прр';
	}
	if (item.is_prr) {
		if (item.description) {
			popupPrrContent = item.description;
		} else {
			popupPrrContent = 'Примечания нет';
		}
	}

	const userData = getFromStorage('user_data');
	let isShipper = false;

	if (userData?.company_type === 'shipper') {
		isShipper = true;
	}

	return (
		<Grid className="req-item with-labels">
			<Grid.Row className="req-item__header">
				<div className="req-item__header-checkbox">
					<Field name={`check[${item.id}]`} type="checkbox" component={FormCheckboxField} />
				</div>
				<Popup
					content={
						// eslint-disable-next-line react/no-danger
						<div dangerouslySetInnerHTML={{ __html: item.info?.more }} />
					}
					trigger={
						<Link to={item.link}>
							<div className="info-icon" />
						</Link>
					}
					disabled={!item.info?.more}
					position="top center"
					size="mini"
					inverted
					style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
				/>
				{item.company && (
					<Grid.Column width={10} className="req-item__header-title">
						{item.company.name}
					</Grid.Column>
				)}

				<Grid.Column width={3} className="req-item__header-uid">
					{item.uid}
					{item.custom_id ? `/${item.custom_id}` : ''}
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="req-item__body">
				<Grid.Column className="req-item__body-left">
					{item.renderPoints?.map((el, index) => {
						return (
							<div className="req-item__location" key={`${el.req_id}${index + 1}`}>
								<div className="req-item__location-icon">
									<img src={index === 0 ? locationIcon : locationIconSuccess} alt="location-icon" />
									<div className="req-item__location-title">
										{isShipper
											? el.partner?.name ?? '---'
											: el.address?.city_with_type ?? el.address?.settlement_with_type ?? '---'}
									</div>
								</div>
								<div className="req-item__location-info">
									<div className="req-item__location-region">
										{el.address?.region_with_type ?? 'Не указан'}
									</div>
									{item.additionalPoints > 0 && index === 0 && (
										<Popup
											content={
												<div
													className="reqs-event-tab__table"
													// eslint-disable-next-line react/no-danger
													dangerouslySetInnerHTML={{ __html: item.info?.points }}
												/>
											}
											disabled={!item.info?.points}
											trigger={
												<div className="req-item__location-points">
													<div className="req-item__location-points-line">{}</div>
													<div className="req-item__location-points-circle">
														{item.additionalPoints}
													</div>
													<div className="req-item__location-points-text">
														доп. точеĸ разгрузĸи
													</div>
												</div>
											}
											position="top center"
											size="mini"
											inverted
											style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
										/>
									)}
								</div>
							</div>
						);
					})}
				</Grid.Column>
				<Grid.Column className="req-item__body-right">
					<div className="req-item__body-right-header">{item.type.name}</div>
					<Grid columns={3}>
						<Grid.Column width={6} className="req-item__body-right-column">
							<div className="req-item__body-label">Дата отгрузки</div>
							<Badge
								indicator={item.points[0].date_info}
								text={
									item.points[0].date
										? new Date(item.points[0].date).toLocaleDateString('ru-RU')
										: '-'
								}
							/>
							<div className="req-item__body-label">Доставить до</div>
							<Badge
								indicator={item.points.slice(-1)[0].date_info}
								text={
									item.points.slice(-1)[0].date
										? new Date(item.points.slice(-1)[0].date).toLocaleDateString('ru-RU')
										: '-'
								}
							/>
						</Grid.Column>
						<Grid.Column width={5} className="req-item__body-right-column">
							<Popup
								content={
									<p>
										<b>Температурный режим:</b> {item.mode ? `${item.mode.name} °C` : 'Не указан'}
									</p>
								}
								trigger={
									<div className="req-item__body-badge-wrapper with-icon">
										<Badge text={item.mode ? `${item.mode.name}` : 'Нет'} isSmall />
										{GradusIcon}
									</div>
								}
								position="top center"
								size="mini"
								inverted
								style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
							/>
							<Popup
								content={
									<p>
										<b>Объем:</b>{' '}
										{item.volume !== null ? `${thousandSeparator(item.volume)} м3` : 'Не указан'}
									</p>
								}
								trigger={
									<div className="req-item__body-badge-wrapper with-icon">
										<Badge
											text={item.volume !== null ? `${thousandSeparator(item.volume)}` : '-'}
											isSmall
										/>
										{VolumeIcon}
									</div>
								}
								position="top center"
								size="mini"
								inverted
								style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
							/>
							<Popup
								content={popupPrrContent}
								trigger={
									<div className="req-item__body-badge-wrapper req-item__body-badge-wrapper_with-checkbox">
										<img src={item.is_prr ? checkIconChecked : checkIcon} />
										<div className="req-item__body-checkbox-label">Погрузка, разгрузка</div>
									</div>
								}
								position="top center"
								size="mini"
								inverted
								style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
							/>
						</Grid.Column>
						<Grid.Column width={5} className="req-item__body-right-column">
							<Popup
								content={
									<p>
										<b>Масса:</b>{' '}
										{item.weight !== null ? `${thousandSeparator(item.weight)} кг` : 'Не указана'}
									</p>
								}
								trigger={
									<div className="req-item__body-badge-wrapper with-icon">
										<Badge
											text={item.weight !== null ? `${thousandSeparator(item.weight)}` : '-'}
											isSmall
										/>
										{KgIcon}
									</div>
								}
								position="top center"
								size="mini"
								inverted
								style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
							/>
							<Popup
								content={
									<p>
										<b>Места:</b>{' '}
										{item.place !== null ? `${thousandSeparator(item.place)} шт` : 'Не указаны'}
									</p>
								}
								trigger={
									<div className="req-item__body-badge-wrapper with-icon">
										<Badge
											text={item.place !== null ? `${thousandSeparator(item.place)}` : '-'}
											isSmall
										/>
										{PieceIcon}
									</div>
								}
								position="top center"
								size="mini"
								inverted
								style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
							/>
							<Link to={item.link} className="req-item__body-link">
								Подробнее
							</Link>
						</Grid.Column>
					</Grid>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="req-item__footer">
				{item.labels &&
					item.labels.map(({ text, color }) => (
						<Grid.Column width={3} className="req-item__footer-label">
							<Status
								status={{
									name: text,
									color,
								}}
							/>
						</Grid.Column>
					))}
			</Grid.Row>
		</Grid>
	);
};

ReqItemComponent.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.number.isRequired,
		uid: PropTypes.number.isRequired,
		custom_id: PropTypes.string.isRequired,
		info: PropTypes.shape({
			more: PropTypes.string,
			points: PropTypes.string,
		}).isRequired,
		labels: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				color: PropTypes.oneOf(['danger', 'primary', 'success', 'secondary']),
			}),
		),
		company: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}).isRequired,
		type: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}).isRequired,
		points: PropTypes.arrayOf(
			PropTypes.shape({
				address: PropTypes.shape({
					city_with_type: PropTypes.string.isRequired,
					region_with_type: PropTypes.string.isRequired,
				}).isRequired,
				partner: PropTypes.shape({
					name: PropTypes.string.isRequired,
				}).isRequired,
				date: PropTypes.string.isRequired,
				date_info: PropTypes.shape({
					status: PropTypes.string,
					description: PropTypes.string,
				}),
			}),
		).isRequired,
		mode: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}).isRequired,
		description: PropTypes.string,
		link: PropTypes.string.isRequired,
		is_prr: PropTypes.bool.isRequired,
		volume: PropTypes.number.isRequired,
		weight: PropTypes.number.isRequired,
		place: PropTypes.number.isRequired,
		additionalPoints: PropTypes.number.isRequired,
		renderPoints: PropTypes.arrayOf(
			PropTypes.shape({
				address: PropTypes.shape({
					city_with_type: PropTypes.string.isRequired,
					region_with_type: PropTypes.string.isRequired,
				}).isRequired,
				partner: PropTypes.shape({
					name: PropTypes.string.isRequired,
				}).isRequired,
				date: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
};
