import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { setClaimsListAction, setIsClaimsListLoadingAction, setPaginationAction, GET_CLAIMS_LIST } from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { errorHandler } from '../../api/utils';
import { getClaimsRequest } from '../../api/claims';

export function* getClaimsListSaga({ payload: { status, searchQuery } }) {
	try {
		yield put(setIsClaimsListLoadingAction(true));
		yield put(setClaimsListAction([]));

		const { data, meta, message } = yield call(getClaimsRequest, status, searchQuery);

		if (data) {
			yield put(
				setClaimsListAction(
					data.map(claim => ({
						...claim,
						link: `/claims/${claim.id}`,
						created_at: new Date(claim.created_at).toLocaleDateString('ru-RU'),
					})),
				),
			);

			if (meta) {
				yield put(setPaginationAction(meta));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsClaimsListLoadingAction(false));
	}
}

export default function* claimsContainerSaga() {
	yield takeEvery(GET_CLAIMS_LIST, getClaimsListSaga);
}
