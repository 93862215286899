export default function cancelModal(query) {
	if (query.get('fromModal')) {
		query.set('modal', query.get('fromModal'));
		if (query.has('req_id')) {
			query.set('id', query.get('req_id'));
		}
		if (query.has('fromTab')) {
			query.set('tab', query.get('fromTab'));
		}
	} else {
		query.delete('modal');
		query.delete('id');
	}
	query.delete('fromModal');
	query.delete('pointId');
	query.delete('req_id');
	query.delete('fromTab');
}
