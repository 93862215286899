/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { DocumentAttachmentFormView } from '../../../document-attachment-form/components/document-attachment-form-view';
import { useInjectSaga } from '../../../../utils/injectSaga';
import { useInjectReducer } from '../../../../utils/injectReducer';
import saga from '../../saga';
import reducer from '../../reducer';
import {
	getClaimDocumentsErrorsSelector,
	getIsClaimDocumentsModalShownSelector,
	getClaimsFilesSelector,
	getClaimsFormSelector,
	getIsClaimsFormLoadingSelector,
} from '../../selectors';
import { getClaimsFormAction, postClaimDocsAction, setClaimDocsErrorsAction, setDocsFilesAction } from '../../actions';
import { CLAIMS_DOCUMENTS_FORM_NAME } from '../../constants';
import { validate } from '../../../document-attachment-form/utils';

const ClaimsDocumentsFormWrapper = ({
	onGetDocuments,
	onPostDocuments,
	onSetFiles,
	onSetErrors,
	onResetForm,
	isModalShown,
	documents,
	files,
	formErrors,
	isLoading,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'claimsDetailsModalSaga', saga });
	useInjectReducer({ key: 'claimsDetailsModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetDocuments({
				id: query.get('id'),
				req_id: query.get('req_id'),
			});
		}
	}, [isModalShown]);

	const handleClose = () => {
		query.set('modal', 'claims_details');
		query.set('tab', 'docs');
		history.push(`${pathname}?${query}`);
		onResetForm(CLAIMS_DOCUMENTS_FORM_NAME);
		onSetFiles([]);
	};

	const handleSave = async () => {
		const { isValid, errors } = validate(documents, files);

		if (!isValid) {
			onSetErrors(errors);
		} else {
			onSetErrors({});
			onPostDocuments({
				id: query.get('id'),
				req_id: query.get('req_id'),
				callback: handleClose,
			});
		}
	};

	const handleFileUploadedChange = (e, key) => {
		const newFiles = [];

		const length = e.target.files?.length;

		[].forEach.call(e.target.files, file => {
			if (file) {
				newFiles.push({ name: file.name, file });

				if (newFiles.length === length) {
					onSetFiles({
						...files,
						[key]: [...(files[key] || []), ...newFiles],
					});
				}
			}
		});

		e.target.value = '';
	};

	const handleUploadedFileDelete = (name, key) => {
		onSetFiles({ ...files, [key]: files[key].filter(file => file.name !== name) });
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText="Прикрепить документы"
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleClose }]}
			modalContent={
				<DocumentAttachmentFormView
					documents={documents}
					files={files}
					onFileUploadedChange={handleFileUploadedChange}
					onUploadedFileDelete={handleUploadedFileDelete}
					formErrors={formErrors}
					isFormLoading={isLoading}
				/>
			}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							text: 'Сохранить',
							color: 'primary',
							onClick: handleSave,
							disabled: isLoading,
						},
						{
							text: 'Закрыть',
							color: 'secondary',
							onClick: handleClose,
						},
					]}
				/>
			}
		/>
	);
};

ClaimsDocumentsFormWrapper.propTypes = {
	onGetDocuments: PropTypes.func.isRequired,
	onPostDocuments: PropTypes.func.isRequired,
	onSetFiles: PropTypes.func.isRequired,
	onSetErrors: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	documents: PropTypes.arrayOf(PropTypes.shape({})),
	files: PropTypes.array,
	formErrors: PropTypes.objectOf(PropTypes.string),
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsClaimDocumentsModalShownSelector(),
	documents: getClaimsFormSelector(),
	files: getClaimsFilesSelector(),
	formErrors: getClaimDocumentsErrorsSelector(),
	isLoading: getIsClaimsFormLoadingSelector(),
});

const mapDispatchToProps = {
	onGetDocuments: getClaimsFormAction,
	onPostDocuments: postClaimDocsAction,
	onSetFiles: setDocsFilesAction,
	onSetErrors: setClaimDocsErrorsAction,
	onResetForm: reset,
};

const ClaimsDocumentsForm = connect(mapStateToProps, mapDispatchToProps)(ClaimsDocumentsFormWrapper);

export default reduxForm({
	form: CLAIMS_DOCUMENTS_FORM_NAME,
	enableReinitialize: true,
})(ClaimsDocumentsForm);
