/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME, TABLE_ROWS_NAMES } from './constants';
import { normalizePhone } from './utils';

export const selectCompaniesModalDomain = state => state.companiesModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getCompanyInfoSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ companyInfo }) => {
		if (!companyInfo) {
			return [];
		}

		const data = {
			type: companyInfo.type?.name || '-',
			inn: companyInfo.inn || '-',
			ogrn: companyInfo.ogrn || '-',
			address: companyInfo.address || '-',
			contract: companyInfo.contract || 'Нет',
			saas: companyInfo.saas || 'Нет',
			is_accredited: companyInfo.is_accredited ? 'Да' : 'Нет',
			is_validated: companyInfo.is_validated ? 'Да' : 'Нет',
			has_contract: companyInfo.has_contract ? 'Да' : 'Нет',
			has_saas: companyInfo.has_saas ? 'Да' : 'Нет',
			contact: {
				first_name: companyInfo.contact?.first_name || '-',
				last_name: companyInfo.contact?.last_name || '-',
				middle_name: companyInfo.contact?.middle_name,
				phone: companyInfo.contact?.phone || '-',
				email: companyInfo.contact?.email || '-',
				extension: companyInfo.contact?.extension || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: TABLE_ROWS_NAMES[key], value }];
		}, []);
	});

export const getIsCompanyInfoLoadingSelector = () =>
	createSelector(
		selectCompaniesModalDomain,
		({ isCompanyInfoLoading, isCompanySaving }) => isCompanyInfoLoading || isCompanySaving,
	);

export const getCompanyNameSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ companyInfo }) => companyInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && (query?.modal === 'company' || query?.modal === 'profile_company'));
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, getIsModalShownSelector(), ({ location: { query } }, isModalShown) => {
		return Boolean(isModalShown && query?.tab === 'audit');
	});

export const getActiveCompanyIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'company' || query?.modal === 'profile_company' ? query?.id : null;
	});

export const getIsCompanyEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, getIsModalShownSelector(), ({ location: { query } }, isModalShown) => {
		return Boolean(isModalShown && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getIsProfileCompanyFormSelector = () =>
	createSelector(selectRouterDomain, getIsModalShownSelector(), ({ location: { query } }, isModalShown) => {
		return Boolean(isModalShown && query?.modal === 'profile_company');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ companyInfo }) => {
		if (!companyInfo) {
			return {};
		}

		return {
			company: {
				type_id: companyInfo.type?.id || '',
				name: companyInfo.name || '',
				inn: companyInfo.inn || '',
				ogrn: companyInfo.ogrn || '',
				address: companyInfo.address || '',
				contract_number: companyInfo.contract_number || '',
				contract_date: companyInfo.contract_date || '',
				saas_number: companyInfo.saas_number || '',
				saas_date: companyInfo.saas_date || '',
				is_accredited: companyInfo.is_accredited || '',
				is_validated: companyInfo.is_validated || '',
				has_contract: companyInfo.has_contract || '',
				has_saas: companyInfo.has_saas || '',
			},
			contact: {
				first_name: companyInfo.contact?.first_name || '',
				last_name: companyInfo.contact?.last_name || '',
				middle_name: companyInfo.contact?.middle_name || '',
				phone: companyInfo.contact?.phone ? normalizePhone(companyInfo.contact?.phone) : '',
				extension: companyInfo.contact?.extension || '',
				email: companyInfo.contact?.email || '',
			},
		};
	});

export const getDataForUpdateCompanySelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getIsCompaniesTypesLoadingSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ isCompaniesTypesLoading }) => isCompaniesTypesLoading);

export const getCompaniesTypesSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ companiesTypes }) => companiesTypes);

export const getCompaniesSelector = () => createSelector(selectCompaniesModalDomain, ({ companies }) => companies);

export const getCompaniesSearchValueSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ companiesSearchValue }) => companiesSearchValue);

export const getCompaniesOptionsSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ companies }) =>
		companies.map(company => ({
			key: `${company.data.inn}${company.data.kpp}`,
			value: company.data.inn,
			data: {
				title: company.value,
				address: `${company.data?.kpp || ''} ${company.data?.address?.value || ''}`,
				deleted: company.data?.state?.status === 'LIQUIDATED',
			},
		})),
	);

export const getIsCompaniesLoadingSelector = () =>
	createSelector(selectCompaniesModalDomain, ({ isCompaniesLoading }) => isCompaniesLoading);
