import { CustomCell } from '../../components/custom-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const PARTNERS_TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Название организации', id: 'name', width: '569px', customComponent: CustomCell },
	{ name: 'ИНН', id: 'inn', width: '683px' },
	{ name: 'ОГРН', id: 'ogrn', width: '683px' },
];
