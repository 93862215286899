import {
	RESET_STATE,
	SET_EVENT_DATA,
	SET_IS_EVENT_DATA_LOADING,
	SET_IS_REQ_DETAILS_LOADING,
	SET_IS_REQ_DOCS_LOADING,
	SET_REQ_DETAILS,
	SET_REQ_DOCS,
	SET_REQ_CLAIMS,
	SET_IS_REQ_CLAIMS_LOADING,
	SET_CLAIM_THEMES,
	SET_IS_CLAIM_THEMES_LOADING,
	SET_CLAIM_CATEGORIES,
	SET_IS_CLAIM_CATEGORIES_LOADING,
	SET_CLAIM_PRIORITIES,
	SET_IS_CLAIM_PRIORITIES_LOADING,
	SET_IS_CLAIM_LOADING,
} from './actions';

export const initialState = {
	reqDetails: null,
	isReqDetailsLoading: false,
	reqDocs: null,
	isReqDocsLoading: false,
	eventData: null,
	isEventDataLoading: false,
	reqClaims: [],
	isReqClaimsLoading: false,
	claimThemes: [],
	isThemesLoading: false,
	claimCategories: [],
	isCategoriesLoading: false,
	claimPriorities: [],
	isPrioritiesLoading: false,
	isClaimLoading: false,
};

const reqsDetailsModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_REQ_DETAILS:
			return {
				...state,
				reqDetails: payload,
			};
		case SET_IS_REQ_DETAILS_LOADING:
			return {
				...state,
				isReqDetailsLoading: payload,
			};
		case SET_REQ_DOCS:
			return {
				...state,
				reqDocs: payload,
			};
		case SET_IS_REQ_DOCS_LOADING:
			return {
				...state,
				isReqDocsLoading: payload,
			};
		case SET_EVENT_DATA:
			return {
				...state,
				eventData: payload,
			};
		case SET_IS_EVENT_DATA_LOADING:
			return {
				...state,
				isEventDataLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		case SET_REQ_CLAIMS:
			return {
				...state,
				reqClaims: payload,
			};
		case SET_IS_REQ_CLAIMS_LOADING:
			return {
				...state,
				isReqClaimsLoading: payload,
			};
		case SET_CLAIM_THEMES:
			return {
				...state,
				claimThemes: payload,
			};
		case SET_IS_CLAIM_THEMES_LOADING:
			return {
				...state,
				isThemesLoading: payload,
			};
		case SET_CLAIM_CATEGORIES:
			return {
				...state,
				claimCategories: payload,
			};
		case SET_IS_CLAIM_CATEGORIES_LOADING:
			return {
				...state,
				isCategoriesLoading: payload,
			};
		case SET_CLAIM_PRIORITIES:
			return {
				...state,
				claimPriorities: payload,
			};
		case SET_IS_CLAIM_PRIORITIES_LOADING:
			return {
				...state,
				isPrioritiesLoading: payload,
			};
		case SET_IS_CLAIM_LOADING:
			return {
				...state,
				isClaimLoading: payload,
			};
		default:
			return state;
	}
};

export default reqsDetailsModalReducer;
