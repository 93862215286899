import {
	RESET_STATE,
	SET_PARTNERS,
	SET_IS_PARTNERS_LOADING,
	SET_ADDRESSES,
	SET_IS_ADDRESSES_LOADING,
	SET_CONTACTS,
	SET_IS_CONTACTS_LOADING,
	SET_IS_DICTIONARIES_LOADING,
	SET_MODES,
	SET_TYPES,
	SET_PARTNERS_FORM_OPTIONS,
	SET_IS_PARTNERS_FORM_OPTIONS_LOADING,
	SET_PARTNERS_SEARCH_VALUE,
	SET_ADDRESS_FORM_OPTIONS,
	SET_IS_ADDRESS_FORM_OPTIONS_LOADING,
	SET_ADDRESS_FORM_SEARCH_VALUE,
	SET_ACTIVE_POINT_INDEX,
	SET_REQUEST_INFO,
	SET_ADDRESS_SEARCH_VALUE,
	SET_CONTACT_SEARCH_VALUE,
	SET_PARTNER_SEARCH_VALUE,
	DELETE_POINT_INFO,
	SET_FEATURES,
	SET_RATES,
	SET_TENDER_TYPES,
	SET_TENDER_COMPANIES,
	SET_IS_REQUEST_INFO_LOADING,
} from './actions';

export const initialState = {
	requestInfo: null,
	isRequestInfoLoading: false,
	activePointIndex: -1,
	isPartnersLoading: false,
	partners: { 0: [], 1: [] },
	isAddressesLoading: false,
	addresses: { 0: [], 1: [] },
	isContactsLoading: false,
	contacts: { 0: [], 1: [] },
	isDictionariesLoading: false,
	modes: [],
	types: [],
	features: [],
	rates: [],
	tenderTypes: [],
	tenderCompanies: [],
	partnersFormOptions: [],
	isPartnersFormOptionsLoading: false,
	partnersFormSearchValue: '',
	addressFormOptions: [],
	isAddressFormOptionsLoading: false,
	addressFormSearchValue: '',
	partnerSearchValue: { 0: '', 1: '' },
	addressSearchValue: { 0: '', 1: '' },
	contactSearchValue: { 0: '', 1: '' },
};

const requestFormReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ACTIVE_POINT_INDEX:
			return {
				...state,
				activePointIndex: payload,
			};
		case SET_REQUEST_INFO:
			return {
				...state,
				requestInfo: payload,
			};
		case SET_IS_REQUEST_INFO_LOADING:
			return {
				...state,
				isRequestInfoLoading: payload,
			};
		case SET_PARTNERS:
			return {
				...state,
				partners: payload,
			};
		case SET_IS_PARTNERS_LOADING:
			return {
				...state,
				isPartnersLoading: payload,
			};
		case SET_PARTNER_SEARCH_VALUE:
			return {
				...state,
				partnerSearchValue: payload,
			};
		case SET_ADDRESSES:
			return {
				...state,
				addresses: payload,
			};
		case SET_IS_ADDRESSES_LOADING:
			return {
				...state,
				isAddressesLoading: payload,
			};
		case SET_CONTACTS:
			return {
				...state,
				contacts: payload,
			};
		case SET_IS_CONTACTS_LOADING:
			return {
				...state,
				isContactsLoading: payload,
			};
		case SET_IS_DICTIONARIES_LOADING:
			return {
				...state,
				isDictionariesLoading: payload,
			};
		case SET_MODES:
			return {
				...state,
				modes: payload,
			};
		case SET_TYPES:
			return {
				...state,
				types: payload,
			};
		case SET_PARTNERS_FORM_OPTIONS:
			return {
				...state,
				partnersFormOptions: payload,
			};
		case SET_IS_PARTNERS_FORM_OPTIONS_LOADING:
			return {
				...state,
				isPartnersFormOptionsLoading: payload,
			};
		case SET_PARTNERS_SEARCH_VALUE:
			return {
				...state,
				partnersFormSearchValue: payload,
			};
		case SET_ADDRESS_FORM_OPTIONS:
			return {
				...state,
				addressFormOptions: payload,
			};
		case SET_IS_ADDRESS_FORM_OPTIONS_LOADING:
			return {
				...state,
				isAddressFormOptionsLoading: payload,
			};
		case SET_ADDRESS_FORM_SEARCH_VALUE:
			return {
				...state,
				addressFormSearchValue: payload,
			};
		case SET_ADDRESS_SEARCH_VALUE:
			return {
				...state,
				addressSearchValue: payload,
			};
		case SET_CONTACT_SEARCH_VALUE:
			return {
				...state,
				contactSearchValue: payload,
			};
		case SET_FEATURES:
			return {
				...state,
				features: payload,
			};
		case SET_RATES:
			return {
				...state,
				rates: payload,
			};
		case SET_TENDER_TYPES:
			return {
				...state,
				tenderTypes: payload,
			};
		case SET_TENDER_COMPANIES:
			return {
				...state,
				tenderCompanies: payload,
			};
		case DELETE_POINT_INFO:
			return {
				...state,
				partners: payload.partners,
				addresses: payload.addresses,
				contacts: payload.contacts,
				partnerSearchValue: payload.partnerSearchValue,
				addressSearchValue: payload.addressSearchValue,
				contactSearchValue: payload.contactSearchValue,
				requestInfo: payload.requestInfo,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default requestFormReducer;
