/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import { isEmpty } from 'lodash';
import { initialState } from './reducer';
import { checkEquality, getFormattedRules, normalizePhone } from './utils';
import { FORM_NAME, PROFILE_TABLE_ROWS_NAMES } from './constants';

export const selectProfileModalDomain = state => state.profileModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getUserInfoSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => {
		if (!userInfo) {
			return [];
		}

		const data = {
			company: {
				name: userInfo.company?.name || '-',
				role: userInfo.company?.type.name || '-',
				id: userInfo.company?.id || '',
			},
			role: userInfo.role?.name || '-',
			post: userInfo.post || '-',
			email: userInfo.email || '-',
			phone: `${userInfo.phone}${userInfo.extension ? `, ${userInfo.extension}` : ''}` || '-',
			telegramId: userInfo.telegram_id || '-',
			isNotify: userInfo.is_notify ? 'Да' : 'Нет',
			author: {
				name: `${userInfo.user?.last_name || '-'} ${userInfo.user?.first_name || '-'}`,
				role: userInfo.user?.role.name || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: PROFILE_TABLE_ROWS_NAMES[key], value }];
		}, []);
	});

export const getIsUserInfoLoadingSelector = () =>
	createSelector(
		selectProfileModalDomain,
		({ isUserInfoLoading, isProfileSaving }) => isUserInfoLoading || isProfileSaving,
	);

export const getIsProfileFormOpenSelector = () =>
	createSelector(selectProfileModalDomain, ({ isProfileFormOpen }) => isProfileFormOpen);

export const getStatusToggleFormSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return {
			isNotifyTodo: !formValues?.is_notify,
			isNotifyTodoCompany: !formValues?.is_notify || !formValues?.is_notify_todo,
		};
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectProfileModalDomain, getFormValues(FORM_NAME), ({ userInfo }, formValues) => {
		if (!userInfo) {
			return {};
		}

		const isNotify = checkEquality('is_notify', userInfo, formValues);

		const isNotifyTodo = isNotify ? checkEquality('is_notify_todo', userInfo, formValues) : false;

		const isNotifyTodoCompany = isNotifyTodo
			? checkEquality('is_notify_todo_company', userInfo, formValues)
			: false;

		return {
			first_name: userInfo.first_name || '',
			last_name: userInfo.last_name || '',
			middle_name: userInfo.middle_name || '',
			post: userInfo.post || '',
			email: userInfo.email || '',
			phone: normalizePhone(userInfo.phone) || '',
			extension: userInfo.extension || '',
			password: userInfo.password || '',
			telegram_id: userInfo.telegram_id || '',
			redirect_ids: userInfo.redirect_ids || [],
			is_notify: isNotify,
			is_notify_todo: isNotifyTodo,
			is_notify_todo_company: isNotifyTodoCompany,
		};
	});

export const getUserNameSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => {
		return userInfo?.first_name && userInfo?.last_name
			? `${userInfo?.first_name} ${userInfo?.last_name} ${userInfo?.middle_name ?? ''}`
			: '-';
	});

export const getDataForSaveProfileSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => ({
		...formValues,
		redirect_ids: formValues.redirect_ids.length < 1 ? null : formValues.redirect_ids,
	}));

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'profile');
	});

export const getIsAvailableAddressesButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'addresses'));

export const getIsAvailableCarsButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'cars'));

export const getIsAvailableContactsButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'contacts'));
export const getIsAvailableDetailsButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'details'));

export const getIsAvailablePartnersButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'partners'));

export const getIsAvailableReqsButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'reqs'));

export const getIsAvailableTrailersButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'trailers'));

export const getIsAvailableUsersButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'users'));
export const getIsAvailableUsersRolesButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'users_roles'));

export const getIsAvailableWaysButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'ways'));

export const getIsAvailableCompaniesButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'companies'));

export const getIsAvailableRelationshipButtonsSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => getFormattedRules(userRules, 'companies_relationship'));

export const getIsAvailableContactsSectionSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => Boolean(userRules?.contacts?.access));

export const getIsAvailableAddressesSectionSelector = () =>
	createSelector(selectProfileModalDomain, ({ userRules }) => Boolean(userRules?.addresses?.access));

export const getRequestsCountSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => {
		return userInfo?.badges?.reqs || 0;
	});

export const getNotificationsCountSelector = () =>
	createSelector(selectProfileModalDomain, ({ notificationsCount }) => notificationsCount);

export const getChatsNotificationsCountSelector = () =>
	createSelector(selectProfileModalDomain, ({ chatsNotificationsCount }) => chatsNotificationsCount);

export const getUserIdSelector = () => createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.id);

export const getCompanyIdSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.company?.id);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getIsUsersListLoadingSelector = () =>
	createSelector(selectProfileModalDomain, ({ isUsersListLoading }) => isUsersListLoading);

export const getUsersListSelector = () => createSelector(selectProfileModalDomain, ({ usersList }) => usersList);
