import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { CONTACT_TABLE_ROWS_NAMES, CONTACT_FORM_NAME } from './constants';

export const selectContactModalDomain = state => state.contactModalReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getIsContactInfoLoadingSelector = () =>
	createSelector(
		selectContactModalDomain,
		({ isContactInfoLoading, isContactDeleting, isContactUpdating }) =>
			isContactInfoLoading || isContactDeleting || isContactUpdating,
	);

export const getContactInfoSelector = () =>
	createSelector(selectContactModalDomain, ({ contactInfo }) =>
		Object.entries(CONTACT_TABLE_ROWS_NAMES).reduce((result, [key, value]) => {
			if (key === 'author') {
				result.push({
					id: key,
					title: value,
					value: {
						name: `${contactInfo?.user?.last_name || ''} ${contactInfo?.user?.first_name || ''}`,
						role: contactInfo?.user?.role?.name || '',
					},
				});

				return result;
			}

			if (key === 'address') {
				result.push({
					id: key,
					title: value,
					value: {
						address: contactInfo?.address?.name || '---',
						id: contactInfo?.address?.id,
					},
				});

				return result;
			}

			if (key === 'phone') {
				result.push({
					id: key,
					title: value,
					value: `${contactInfo[key]}${contactInfo?.extension ? `, ${contactInfo?.extension}` : ''}` || '---',
				});

				return result;
			}

			if (key === 'is_default') {
				result.push({ id: key, title: value, value: contactInfo[key] ? 'Да' : 'Нет' });
				return result;
			}

			result.push({ id: key, title: value, value: contactInfo[key] ? String(contactInfo[key]) : '---' });

			return result;
		}, []),
	);

export const getFormInitialValuesSelector = () =>
	createSelector(selectContactModalDomain, getFormValues(CONTACT_FORM_NAME), ({ contactInfo }, formValues) => {
		// if (isEmpty(contactInfo) && isEmpty(formValues)) {
		// 	return { is_default: true };
		// }

		return { ...contactInfo, ...formValues };
	});

export const getContactNameSelector = () =>
	createSelector(selectContactModalDomain, ({ contactInfo }) => {
		return contactInfo
			? `${contactInfo.last_name || ''} ${contactInfo.first_name || ''} ${contactInfo.middle_name || ''}`
			: '-';
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'contact');
	});

export const getActiveContactIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'contact' ? query?.contactId : null;
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'contact' && query?.contactId && query?.tab === 'audit');
	});

export const getActiveAddressIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query, pathname } }) => {
		if (pathname.includes('partners') && query?.modal === 'contact') {
			return query?.addressId;
		}

		if (!pathname.includes('partners') && query?.modal === 'contact') {
			return query?.id;
		}

		return null;
	});

export const getIsContactAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'contact' && query?.mode === 'add');
	});

export const getIsContactEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'contact' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getContactFormValuesSelector = () =>
	createSelector(getFormValues(CONTACT_FORM_NAME), formValues => formValues);

export const getContactSearchValueSelector = () =>
	createSelector(selectContactModalDomain, ({ contactSearchValue }) => contactSearchValue);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(CONTACT_FORM_NAME), isInvalid => isInvalid);

export const getCurrentPathnameSelector = () =>
	createSelector(selectRouterDomain, ({ location: { pathname } }) => pathname);
