import { CustomCell } from '../../components/custom-cell';

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Модель', id: 'name', width: '420px', customComponent: CustomCell },
	{ name: 'Тип прицепа', id: 'type_name', width: '360px' },
	{ name: 'Номер', id: 'number', width: '260px' },
	{ name: 'Vin номер', id: 'vin', width: '567px' },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
