/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { change, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import {
	createPartnerAction,
	getPartnersFormOptionsAction,
	setPartnersFormOptionsAction,
	setPartnersSearchValueAction,
} from '../../actions';
import {
	getIsPartnersFormOptionsLoadingSelector,
	getPartnersFormOptionsSelector,
	getPartnersFormSearchValueSelector,
} from '../../selectors';
import { ADD_PARTNER_FORM_NAME } from '../../constants';
import { validatePartnerForm } from '../../utils';
import { PartnerFormView } from './partner-form-view';

const RequestPartnerModalWrapper = ({
	onSetPartnersSearchValue,
	onGetPartnersFormOptions,
	partnersFormSearchValue,
	partnersFormOptions,
	isPartnersFormOptionsLoading,
	onFieldChange,
	onCreatePartner,
	onFormSubmit,
	onSetPartnersFormOptions,
}) => {
	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const handlePartnersFormSearchChange = ({ target: { value } }) => {
		onSetPartnersSearchValue(value);
		onGetPartnersFormOptions();
	};

	const handlePartnerChange = value => {
		const currentPartner = partnersFormOptions.find(item => item.value === value);

		if (currentPartner) {
			onFieldChange(ADD_PARTNER_FORM_NAME, 'name', currentPartner.name);
			onFieldChange(ADD_PARTNER_FORM_NAME, 'inn', currentPartner.value);
			onFieldChange(ADD_PARTNER_FORM_NAME, 'ogrn', currentPartner.ogrn);
			onFieldChange(ADD_PARTNER_FORM_NAME, 'address', currentPartner.data.address);
			onSetPartnersSearchValue(currentPartner.name);
		}
	};

	const handleModalClose = () => {
		onSetPartnersFormOptions([]);
		onSetPartnersSearchValue('');
		query.set('modal', 'request');

		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(ADD_PARTNER_FORM_NAME);

		onCreatePartner({ redirect: history.push, searchQuery: query.toString() });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown
			onKeyDown={handleKeyDown}
			headerText="Создать контрагента"
			modalContent={
				<PartnerFormView
					partnersFormOptions={partnersFormOptions}
					isPartnersFormOptionsLoading={isPartnersFormOptionsLoading}
					onPartnersFormSearchChange={handlePartnersFormSearchChange}
					onPartnerChange={handlePartnerChange}
					partnersFormSearchValue={partnersFormSearchValue}
				/>
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'request-partner' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={[]}
					rightButtons={[
						{ onClick: handleFormSubmit, text: 'Сохранить', color: 'primary' },
						{ onClick: handleModalClose, text: 'Отмена', color: 'secondary' },
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: ADD_PARTNER_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validatePartnerForm,
})(RequestPartnerModalWrapper);

const mapStateToProps = createStructuredSelector({
	partnersFormOptions: getPartnersFormOptionsSelector(),
	partnersFormSearchValue: getPartnersFormSearchValueSelector(),
	isPartnersFormOptionsLoading: getIsPartnersFormOptionsLoadingSelector(),
});

const mapDispatchToProps = {
	onSetPartnersSearchValue: setPartnersSearchValueAction,
	onGetPartnersFormOptions: getPartnersFormOptionsAction,
	onFieldChange: change,
	onCreatePartner: createPartnerAction,
	onFormSubmit: submit,
	onSetPartnersFormOptions: setPartnersFormOptionsAction,
};

RequestPartnerModalWrapper.propTypes = {
	onSetPartnersSearchValue: PropTypes.func.isRequired,
	onGetPartnersFormOptions: PropTypes.func.isRequired,
	partnersFormOptions: PropTypes.array.isRequired,
	partnersFormSearchValue: PropTypes.string.isRequired,
	isPartnersFormOptionsLoading: PropTypes.bool.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	onCreatePartner: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSetPartnersFormOptions: PropTypes.func.isRequired,
};

const RequestPartnerModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

export { RequestPartnerModal };
