import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import './styles.less';
import { isString } from 'lodash';
import { notificationDismiss } from '../../../modules/notifications/actions';
import { ModalActionButtons } from '../modal-window/modal-action-buttons';

const NotificationItem = ({ notificationInfo, notificationDismissFunc }) => {
	useEffect(() => {
		setTimeout(() => notificationDismissFunc({ id: notificationInfo.id }), notificationInfo.dismissAfter);
	}, []);

	return (
		<div className="notification">
			<div className="notification__content">
				<div className="notification__content-header">
					<div className={`notification__status notification__status_${notificationInfo.state}`} />
					{notificationInfo.link && isString(notificationInfo.link) ? (
						<Link className="notification__link" to={notificationInfo.link}>
							{notificationInfo.title}
						</Link>
					) : (
						<div className="notification__link">{notificationInfo.title}</div>
					)}
				</div>
				<p className="notification__message">{notificationInfo.message}</p>
			</div>
			<div className="notification__close-button">
				<ModalActionButtons
					buttons={[
						{
							type: 'close',
							onClick: () => notificationDismissFunc({ id: notificationInfo.id }),
							text: notificationInfo.id,
						},
					]}
				/>
			</div>
		</div>
	);
};

NotificationItem.propTypes = {
	notificationInfo: PropTypes.object,
	notificationDismissFunc: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = { notificationDismissFunc: notificationDismiss };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const Notification = compose(withConnect)(NotificationItem);

export { Notification };
