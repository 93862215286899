/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset } from 'redux-form';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { DocumentAttachmentFormView } from './components/document-attachment-form-view';
import {
	getDocumentsSelector,
	getFilesSelector,
	getFormErrorsSelector,
	getIsFormLoadingSelector,
	getIsModalShownSelector,
} from './selectors';
import { getDocumentsAction, resetStateAction, sendFormAction, setFilesAction, setFormErrorsAction } from './actions';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { validate } from './utils';
import { FORM_NAME } from './constants';
import closeModal from '../../utils/closeModal';
import cancelModal from '../../utils/cancelModal';

const DocumentAttachmentFormWrapper = ({
	onResetState,
	isModalShown,
	onSendForm,
	onGetDocuments,
	documents,
	onSetFiles,
	files,
	formErrors,
	onSetFormErrors,
	isFormLoading,
	onResetForm,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'documentAttachmentModalSaga', saga });
	useInjectReducer({ key: 'documentAttachmentModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetDocuments({ id: query.get('id'), pointId: query.get('pointId') });
		}
	}, [isModalShown]);

	const handleModalClose = () => {
		closeModal(query);
		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleGoToDeliveryModal = () => {
		if (query.get('pointId')) {
			query.set('modal', 'cargo_delivery');
			query.delete('pointId');
		} else {
			cancelModal(query);
		}

		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleSaveButtonClick = async () => {
		const { isValid, errors } = validate(documents, files);

		if (!isValid) {
			onSetFormErrors(errors);
		} else {
			onSetFormErrors({});
			onSendForm({
				redirect: handleGoToDeliveryModal,
				id: query.get('id'),
				pointId: query.get('pointId'),
				searchQuery: query.toString(),
			});
		}
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleSaveButtonClick();
		}
	};

	const handleFileUploadedChange = (e, key) => {
		const newFiles = [];

		const length = e.target.files?.length;

		[].forEach.call(e.target.files, file => {
			if (file) {
				newFiles.push({ name: file.name, file });

				if (newFiles.length === length) {
					onSetFiles({
						...files,
						[key]: [...(files[key] || []), ...newFiles],
					});
				}
			}
		});

		e.target.value = '';
	};

	const handleUploadedFileDelete = (name, key) => {
		onSetFiles({ ...files, [key]: files[key].filter(file => file.name !== name) });
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={query.get('pointId') ? 'Прикрепить документы о доставке груза' : 'Прикрепить документы'}
			modalContent={
				<DocumentAttachmentFormView
					documents={documents}
					files={files}
					onFileUploadedChange={handleFileUploadedChange}
					onUploadedFileDelete={handleUploadedFileDelete}
					formErrors={formErrors}
					isFormLoading={isFormLoading}
				/>
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'document_attachment_form' }]}
			headerStatuses={[]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleSaveButtonClick,
							text: 'Сохранить',
							color: 'primary',
							disabled: isFormLoading,
						},
						{
							onClick: handleGoToDeliveryModal,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	documents: getDocumentsSelector(),
	files: getFilesSelector(),
	formErrors: getFormErrorsSelector(),
	isFormLoading: getIsFormLoadingSelector(),
});

const mapDispatchToProps = {
	onSendForm: sendFormAction,
	onResetState: resetStateAction,
	onGetDocuments: getDocumentsAction,
	onSetFiles: setFilesAction,
	onSetFormErrors: setFormErrorsAction,
	onResetForm: reset,
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(DocumentAttachmentFormWrapper);

const DocumentAttachmentForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

DocumentAttachmentFormWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onSendForm: PropTypes.func.isRequired,
	onGetDocuments: PropTypes.func.isRequired,
	documents: PropTypes.arrayOf(PropTypes.shape({})),
	files: PropTypes.object,
	onSetFiles: PropTypes.func.isRequired,
	formErrors: PropTypes.objectOf(PropTypes.string),
	onSetFormErrors: PropTypes.func.isRequired,
	isFormLoading: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
};

export default DocumentAttachmentForm;
