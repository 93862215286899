import { SubmissionError } from 'redux-form';
import { FIELDS_FOR_VALIDATE, TYPES_WITH_DATE_ISSUE_AND_MODES } from './constants';

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		if (key.includes('modes')) {
			const arr = key.split('.');

			if (!result.modes) {
				result.modes = {};
			}

			result.modes = {
				...result.modes,
				[arr[1]]: {
					modes: `${result.modes[arr[1]] || ''} ${value}`,
				},
			};
		}

		return {
			...result,
			[key]: value[0],
		};
	}, {});
};

const getFieldsForValidate = values => {
	const result = [...FIELDS_FOR_VALIDATE];

	if (TYPES_WITH_DATE_ISSUE_AND_MODES.includes(values.type_id)) {
		result.push('type_date_issue', 'mode');
	}

	return result;
};

export const validate = values => {
	getFieldsForValidate(values).forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};

export const getReturnToCarQuery = (query, returnTo, returnMode, returnId) => {
	query.set('modal', returnTo);
	if (returnMode) query.set('mode', returnMode);
	if (returnId) query.set('id', returnId);
	query.delete('tab');
	query.delete('returnTo');
	query.delete('returnMode');
	query.delete('returnId');
	return query;
};
