import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Link } from 'react-router-dom';

const ReqsCustomModalSubValueCell = ({ rowData }) => {
	if (rowData.id === 'bid') {
		return (
			<span className="reqs-custom-modal-sub-value-cell__cell">
				<div className="reqs-custom-modal-sub-value-cell__cell_bold">{rowData.sub_value.name}</div>
				<div className="reqs-custom-modal-sub-value-cell__cell_thin">{rowData.sub_value.date}</div>
			</span>
		);
	}

	if (['customer', 'address_from', 'tk'].includes(rowData.id) || rowData.id.includes('address_to')) {
		return (
			<span className="reqs-custom-modal-sub-value-cell__cell">
				<div className="reqs-custom-modal-sub-value-cell__cell_bold">{rowData.sub_value.name}</div>
				<div className="reqs-custom-modal-sub-value-cell__cell_thin">{rowData.sub_value.code}</div>
			</span>
		);
	}

	if (['driver'].includes(rowData.id)) {
		return (
			<div className="reqs-custom-modal-sub-value-cell__driver">
				{rowData.sub_value.passport && (
					<span className="reqs-custom-modal-sub-value-cell__cell">
						<div className="reqs-custom-modal-sub-value-cell__cell_bold">Паспорт:</div>
						<div className="reqs-custom-modal-sub-value-cell__cell_thin">{rowData.sub_value.passport}</div>
					</span>
				)}
				{rowData.sub_value.driver_license && (
					<span className="reqs-custom-modal-sub-value-cell__cell">
						<div className="reqs-custom-modal-sub-value-cell__cell_bold">Водительское удостоверение:</div>
						<div className="reqs-custom-modal-sub-value-cell__cell_thin">
							{rowData.sub_value.driver_license}
						</div>
					</span>
				)}
			</div>
		);
	}

	if (rowData.id === 'tender_type' && rowData?.sub_value) {
		return (
			<div className="reqs-custom-modal-sub-value-cell__cell">
				<Link className="reqs-custom-modal-sub-value-cell__cell_bold" to={rowData.sub_value?.url}>
					{rowData.sub_value?.text}
				</Link>
			</div>
		);
	}

	return '';
};

ReqsCustomModalSubValueCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { ReqsCustomModalSubValueCell };
