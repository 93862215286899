import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Table } from 'semantic-ui-react';
import { ClaimsCustomModalCell } from '../claims-custom-modal-cell';
import './styles.less';

const ClaimsTableComponent = ({ isLoading, rows }) => {
	return (
		<div className="claims-table">
			{isLoading ? (
				<Loader active size="big" />
			) : (
				<Table basic="very" className="claims-table__table">
					<Table.Body>
						{!rows?.length && (
							<Table.Row>
								<Table.Cell className="claims-table__no-data">Данные не найдены</Table.Cell>
							</Table.Row>
						)}
						{rows?.map(row => (
							<Table.Row key={row.id} className="claims-table__table-row">
								<Table.Cell className="claims-table__table-cell" key={`${row.id}title`}>
									{row.title}
								</Table.Cell>
								<Table.Cell colSpan="2" key={`${row.id}value`} className="claims-table__table-cell">
									<ClaimsCustomModalCell rowData={row} />
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			)}
		</div>
	);
};

ClaimsTableComponent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		}),
	),
};

export { ClaimsTableComponent };
