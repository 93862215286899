import moment from 'moment';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectRequestsListContainerDomain = state => state.requestsListContainerReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const selectProfileDomain = state => state.profileModalReducer || {};

export const getReqsSelector = () => createSelector(selectRequestsListContainerDomain, ({ reqs }) => reqs);

export const getCountSelector = () => createSelector(selectRequestsListContainerDomain, ({ count }) => count);

export const getTimerSelector = () =>
	createSelector(selectRequestsListContainerDomain, ({ timer }) => {
		if (timer?.start) {
			return {
				text: 'Аукцион начнется через: ',
				time: moment(timer?.start).diff(moment(), 's'),
			};
		}

		if (timer?.end) {
			return {
				text: 'Аукцион закончится через: ',
				time: moment(timer?.end).diff(moment(), 's'),
			};
		}

		if (timer?.result) {
			return {
				text: 'Результаты аукциона через: ',
				time: moment(timer?.result).diff(moment(), 's'),
			};
		}

		return null;
	});

export const getIsTimerVisibleSelector = () => createSelector(getTimerSelector(), timer => Boolean(timer));

export const getIsReqsLoadingSelector = () =>
	createSelector(selectRequestsListContainerDomain, ({ isReqsLoading }) => isReqsLoading);

export const getRawPaginationSelector = () =>
	createSelector(selectRequestsListContainerDomain, ({ pagination }) => pagination);

export const getPaginationSelector = () =>
	createSelector(selectRequestsListContainerDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getPathnameSelector = () => createSelector(selectRouterDomain, ({ location: { pathname } }) => pathname);

export const getCanExportSelector = () =>
	createSelector(selectProfileDomain, ({ userRules }) => userRules?.reqs?.export);

export const getShowExportButtonSelector = () =>
	createSelector(getCanExportSelector(), selectRouterDomain, (canExport, router) => {
		if (!canExport) {
			return false;
		}

		const inValidPathsForCarrier = [
			'/reqs/avia',
			'/reqs/avia/auction',
			'/reqs/combined',
			'/reqs/combined/auction',
			'/reqs/car',
			'/reqs/car/auction',
			'/reqs/express',
			'/reqs/express/auction',
		];

		return !inValidPathsForCarrier.some(path => path === router.location.pathname);
	});

export const getReqsSelectedIdsSelector = () =>
	createSelector(getFormValues(FORM_NAME), values => {
		if (!values?.check) {
			return [];
		}

		return Object.keys(values.check).filter(key => values.check[key]);
	});
